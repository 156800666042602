import * as React from "react"
import {EShippingType} from "../../common/common.models"
import {
  PROJECT_COUNTRY,
  PROJECT_SETTINGS,
} from "../../core/APIUtils"
import {GeneralContext} from "../../core/general.context"
import {LabelContext} from "../../core/label.context"
import {CartRecap} from "../cart/cart.recap.component"

import "@adyen/adyen-web/dist/adyen.css"
import { CartContext } from "../../core/cart.context"
import { ILineItem } from "../../common/cart.models"
import { StyledCheckout } from "./checkout.style"
import {CheckoutContext} from "../../core/checkout.context"
import {CheckoutStep1, CheckoutStep2} from "./checkout.steps.component"

export const Checkout = (props: any) => {
    const {redirectResult} = props

  const { cart, shippingMethods, userData } = React.useContext(GeneralContext)
  const {
    setShippingMethod,
    additionalsDetailsPayment,
    processingOrder,
    getSelectedShippingMethod,
    setSelectedShippingMethod
  } = React.useContext(CartContext)
  const {
    shippingType, setShippingType,
    setPrivacy, setConsent3Party,
    setLineItemWithNoAvailability,
    adyenComponent, checkoutStep
  } = React.useContext(CheckoutContext)
  const {t} = React.useContext(LabelContext)

  const componentElement = React.useRef(null)

  React.useEffect(() => {
    const selectedShippingMethod = getSelectedShippingMethod(cart, shippingMethods);
    if (selectedShippingMethod?.custom?.fields?.['shipping-type-list'] === EShippingType.Pickup) {
      setShippingType(EShippingType.Pickup)

      if (cart.shippingInfo || cart.shippingInfo?.shippingMethod?.id === selectedShippingMethod.id) {
          setSelectedShippingMethod(selectedShippingMethod)
      }
    }
  },[shippingMethods])

  React.useEffect(() => {
    if (redirectResult) {
      let details = {
        details: {
          redirectResult: redirectResult,
        },
      }
      additionalsDetailsPayment(details, adyenComponent)
    }
  }, [redirectResult])

  React.useEffect(() => {
    if (["DE", "PL"].includes(PROJECT_COUNTRY)) {
      setPrivacy(true)
    }

    cart.lineItems.map((item: any) => {
      const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
      if (discounts && PROJECT_SETTINGS.externalPromo) {
        item["discountedPrice"]["includedDiscounts"].map((_item: any) => {
          if (_item["discount"]["id"] === PROJECT_SETTINGS.externalPromo)
            setConsent3Party(true)
        })
      }
    })

    if (["PL"].includes(PROJECT_COUNTRY)) {
      cart.lineItems.map((item: ILineItem) => {
        if (item.variant.availability.availableQuantity <= 0) {
          setLineItemWithNoAvailability(true)
        }
      })
    }
  }, [])

  React.useEffect(() => {
    if (componentElement) {
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }, [componentElement])

  React.useEffect(() => {
    if (
        shippingType === EShippingType.Home &&
        cart.shippingAddress?.custom?.fields.internalType !== "placeholder" &&
        cart.billingAddress
    ) {
      if (shippingMethods) {
        let sMethods = shippingMethods?.filter((shippingMethod: any) => shippingMethod.custom?.fields["shipping-type-list"] !== EShippingType.Pickup)
        if (sMethods.length === 1) {
          if (!cart.shippingInfo || cart.shippingInfo?.shippingMethod?.id !== sMethods[0].id) {
            setShippingMethod(sMethods[0].id)
          }
        }
      }

      if(!cart.shippingInfo && shippingMethods){
        let giftCardShippingMethod = shippingMethods?.filter((shippingMethod: any) => shippingMethod.key === "giftcard")
        if(giftCardShippingMethod.length > 0){
          setShippingMethod(giftCardShippingMethod[0].id)
        }
      }
    }
  }, [cart, shippingType, userData])

  const userActionRequiredMessage = t("ecommerce.checkout.user-action-required")

  return (
      <StyledCheckout>
        {userActionRequiredMessage && !userData && <h4>{userActionRequiredMessage}</h4>}
        {processingOrder && <div className="processingOrderMessage title">Creating order <div className="loader">...</div></div>}
        <div style={{position: "relative"}} className={`row no-gutters ${processingOrder && "processingOrderStyle"} row-checkout`}>
          <div className="checkout-column-main col-lg-8">
            <div className="wrapper">
              {checkoutStep === 1 &&
                  <CheckoutStep1/>
              }
              {checkoutStep === 2 &&
                  <CheckoutStep2/>
              }
            </div>
          </div>
          <aside className="checkout-column-aside col-lg-4">
            <CartRecap/>
          </aside>
        </div>
      </StyledCheckout>
  )
}
