import * as React from "react"
import ReactDOM from "react-dom"
import { CartContext } from "../../core/cart.context"
import {calls, PROJECT_SETTINGS} from "../../core/APIUtils"
import { RESTContext } from "../../core/rest.context"
import { LabelContext } from "../../core/label.context"
import { Button } from "../button/button.component"
import { BRX_PRODUCTS_URL, IS_BACK_IN_STOCK_ACTIVE } from "../../core/APIUtils"
import { StyledAlternativeProduct } from "./alternativeProductPopup.style"

export const AlternativeProductPopup = (props: any) => {
  const { setSkuForPopup, addLineItem } = React.useContext(CartContext)
  const { ajax$ } = React.useContext(RESTContext)
  const { t } = React.useContext(LabelContext)
  const container_btn = document.getElementsByClassName("reactPlaceholder_testPopup")

  const [titlePopup, setTitlePopup] = React.useState<string>("")
  const [descPopup, setDescPopup] = React.useState<string>("")
  const [imgUrl, setImgUrl] = React.useState<string>("")
  const [keyType, setKeyType] = React.useState<string>("")
  const [alternativeSku, setAlternativeSku] = React.useState<string>("")
  const [alternativeImgUrl, setAlternativeImgUrl] = React.useState<string>("")
  const [alternativeAvailable, setAlternativeAvailable] = React.useState<number>(0)

  React.useEffect(() => {
    ajax$(calls.Project.getProduct(props.sku || "")).subscribe((res: any) => {
      let keyTitlePopup = res.masterVariant.attributes.find((attr: any) => attr.name === "key-title-popup")?.value
      let keyDescrPopup = res.masterVariant.attributes.find((attr: any) => attr.name === "key-description-popup")?.value
      let keyImageUrl = res.masterVariant.attributes.find((attr: any) => attr.name === "key-img-url")?.value
      let alternativeProduct = res.masterVariant.attributes.find((attr: any) => attr.name === "alternative-product")
        ?.value?.id
      let keyType = res.productType.obj.key
      setTitlePopup(keyTitlePopup)
      setDescPopup(keyDescrPopup)
      setImgUrl(keyImageUrl)
      setKeyType(keyType)

      //alternative product
      ajax$(calls.Project.getProductById(alternativeProduct)).subscribe((res: any) => {
        setAlternativeSku(res.key)
        setAlternativeImgUrl(res.masterVariant?.images[0]?.url)
        setAlternativeAvailable(res.masterVariant.availability.availableQuantity)
      })
    })
  }, [])

  let label_keyTitlePopup = t(titlePopup)
  let label_keyDescPopup = t(descPopup)
  let label_keyImageUrl = t(imgUrl)

  const RedirectBackInStock = () => {
    let path = BRX_PRODUCTS_URL + alternativeSku + "?openBackInStock=true"
    window.location.href = path
  }

  const productFamilyImage = ['COOLING','COOKERS']

  if (!container_btn) return null
  else {
    return (
      <>
        {" "}
        ({document.body.classList.add("modal-open")}
        {Array.from(container_btn).map((link, i) =>
          ReactDOM.createPortal(
            <StyledAlternativeProduct>
              <div
                id="promoPopup"
                className="modal show fade eforms-modal white promoPopUp"
                style={{ display: "block" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        onClick={() => {
                          setSkuForPopup("")
                          document.body.classList.remove("modal-open")
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="container-fluid">
                        <div className="">
                          <h3 className="title title-lg">{label_keyTitlePopup}</h3>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-5">                            
                            {productFamilyImage.includes(keyType) ? 
                              <img
                                src={
                                  "https://bucket-smeg.4flow.cloud/OBJECTS/Tech%20Drawings%20(png)/" +
                                  props.sku +
                                  ".png"
                                }
                                className="productImage"
                                alt={props.sku}
                              />
                            :
                             <img src={label_keyImageUrl} alt="" />
                             }
                          </div>
                          <div className="col-md-7">
                          <div className="description"
                             dangerouslySetInnerHTML={{
                              __html: label_keyDescPopup,
                            }}/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            <Button
                              className="primary color--alert"
                              onClick={() => {
                                addLineItem(props.sku, true)
                                setSkuForPopup("")
                                document.body.classList.remove("modal-open")
                              }}
                              label={`${t("ecommerce.common.continue").replace('[sku]',props.sku)} `}
                            />
                          </div>
                        </div>
                        {alternativeSku && (
                          <>
                            <h3>{t("ecommerce.common.product.replacementProduct")}:</h3>
                            <div className="row alternative-product mb-5 d-flex align-items-center">
                              <div className="col-lg alt-product__img pb-3">
                                <div className="col-md-4 ">
                                  <img src={alternativeImgUrl} className="productImage" alt="" />
                                </div>
                                <div className="col-md-8 d-flex align-items-center">
                                  <div>
                                    <h4>{alternativeSku}</h4>
                                    <a
                                      href={BRX_PRODUCTS_URL + alternativeSku}
                                      target="_blank"
                                      className="action-link"
                                      rel="noreferrer"
                                    >
                                      {t("ecommerce.common.detailProduct")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg text-center" style={{}}>
                                {alternativeAvailable > 0 || PROJECT_SETTINGS.inventoryMode === "TrackOnly" ? (
                                  <>
                                    <Button
                                      className="primary mt-4"
                                      onClick={() => {
                                        addLineItem(alternativeSku, true)
                                        setSkuForPopup("")
                                        document.body.classList.remove(
                                          "modal-open"
                                        ) /*addRemoveWishlistLineItem(props.sku)*/
                                      }}
                                      label={t("ecommerce.common.product.replaceProduct")}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="col backInstock">
                                      <div className="isOutOfStock textIsOutOfStock">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: t("ecommerce.common.product.isOutOfStock"),
                                          }}
                                          className="w-100"
                                        />
                                      </div>
                                      {IS_BACK_IN_STOCK_ACTIVE && (
                                        <Button
                                          onClick={RedirectBackInStock}
                                          className="primary btn_backInStock btn_secondary btnMinSize js_backInStock"
                                          label={t("ecommerce.common.product.notifyMeWhenBackInStock")}
                                          icon="far fa-bell"
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledAlternativeProduct>,
            link
          )
        )}
        );
      </>
    )
  }
}
