import './GlobalStyle.tsx';
import { GeneralContextComponent } from './core/general.context';
import { GlobalStyle } from './GlobalStyle';
import { RESTContextComponent } from './core/rest.context';
import { AlertContextComponent } from './core/alert.context';
import { BannerContextComponent } from './core/banner.context';
import { LabelContextComponent } from './core/label.context';
import { SymbolContextComponent } from './core/symbol.context';
import { CartContextComponent } from './core/cart.context';
import { UserContextComponent } from './core/user.context';
import { Instances } from './core/instances';
import { LoaderContextComponent } from './core/loader.context';
import {CheckoutContextComponent} from "./core/checkout.context"

function App() {
  return (
    <>
      <style>
        {`
        .container-fluid.base-layout {
          @media (min-width: 992px) {
            overflow: visible!important;
          }
          .row.breadcrumb__row {max-width: 100%}
        }
      `}
      </style>
      <GlobalStyle/>
        <RESTContextComponent>
          <LabelContextComponent>
            <SymbolContextComponent>
              <AlertContextComponent>
                <LoaderContextComponent>
                  <BannerContextComponent>
                    <GeneralContextComponent>
                      <CartContextComponent>
                        <UserContextComponent>
                          <CheckoutContextComponent>
                            <Instances />
                          </CheckoutContextComponent>
                        </UserContextComponent>
                      </CartContextComponent>
                    </GeneralContextComponent>
                  </BannerContextComponent>
                </LoaderContextComponent>
              </AlertContextComponent>
            </SymbolContextComponent>
          </LabelContextComponent>
        </RESTContextComponent>
    </>
  );
}

export default App;
