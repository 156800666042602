import * as React from "react"
import { GeneralContext } from "../../core/general.context"
import { Box } from "../box/box.component"
import { StyledUser } from "./user.style"
import { Property } from "../elements/property/property.component"
import { Button } from "../button/button.component"
import { Address } from "../elements/address/address.component"
import { AddAddress } from "../elements/address/address.component"
import { BannerContext } from "../../core/banner.context"
import { LabelContext } from "../../core/label.context"
import { EditUserData } from "./edituser.component"
import { BRX_BASE_URL, calls, PROJECT_COUNTRY, PROJECT_KEY, PROJECT_SETTINGS } from "../../core/APIUtils"
import { UserContext } from "../../core/user.context"
import { RESTContext } from "../../core/rest.context"
import {CheckoutContext} from "../../core/checkout.context"
import {EAddressType, EShippingType, IAddAddressFormMethods} from "../../common/common.models"

export const User = (props: any) => {
  const addAddressFormRef_shipping = React.useRef<IAddAddressFormMethods>(null)
  const addAddressFormRef_billing = React.useRef<IAddAddressFormMethods>(null)
  const { userData } = React.useContext(GeneralContext)
  const { addresses, billingAddressIds, shippingAddressIds } = userData
  const { removeAddress, addMultipleAddress } = React.useContext(UserContext)
  const [selectedAddress, setSelectedAddress] = React.useState<any>()
  const [selectedAddressType, setSelectedAddressType] = React.useState(EAddressType.Shipping)
  const [formEditOpen, setFormEditOpen] = React.useState(false)
  const [emailSentMessage, setEmailSentMessge] = React.useState(false)
  const bannerConsumer = React.useContext(BannerContext)
  const { t } = React.useContext(LabelContext)
  const { ajax$ } = React.useContext(RESTContext)
  const { setIsAddressEdit } = React.useContext(CheckoutContext)

  const billingAddressList = billingAddressIds?.map((item: string) => { return (addresses.find((address: any) => address.id === item)) })
  const shippingAddressList = shippingAddressIds?.map((item: string) => { return (addresses.find((address: any) => address.id === item)) })
  const gender = userData && (t("ecommerce.personalinfo." + userData.salutation))

  const handleVerifyEmail = () => {
    let body_verify = {
      "ttlMinutes": 720,
      "id": userData.id,
      "projectKey": PROJECT_KEY,
      "baseUrl": BRX_BASE_URL
    }
    ajax$(calls.Project.sendVerifyMail(), undefined, body_verify).subscribe(res => {
      setEmailSentMessge(true)
    })
  }

  const handleAddAddressSave = async (type: EAddressType) => {
    const formRef = type === EAddressType.Shipping
        ? addAddressFormRef_shipping.current
        : addAddressFormRef_billing.current

    if(!formRef) return

    const isFormValid = await formRef.addAddressFormValidate()

    if(!isFormValid) return

    const formData = await formRef.addAddressFormGetData()

    if(!userData) return

    const addressToUpdate: { address: any; type: EAddressType }[] = [
      { address: formData, type }
    ]

    addMultipleAddress(addressToUpdate, false, EShippingType.Home).subscribe(
        () => {
          // Resetta il modulo dopo il salvataggio
          formRef.addAddressFormReset()
          setSelectedAddress(null)
          setIsAddressEdit(type, false)
        },
        (err: any) => {
          console.error(`Errore durante il salvataggio del ${type} address:`, err)
        }
    )
  }

  return (
    <StyledUser>

      <Box className="unboxed secondary unbordered">
        <h2>{t("ecommerce.myaccount.personal-info-title")}</h2>
        {!formEditOpen &&
          <Box className="secondary">
            <div className="row">
              <div className="col-md-8">
                <Property label={t("ecommerce.personalinfo.email")} item={userData.email}>
                  <p className="emailVerifiedMessage"><i>{userData.isEmailVerified && t("ecommerce.myaccount.email-verified-short-message")}</i>
                    {!userData.isEmailVerified && !emailSentMessage && <a href="" className="secondary text-bold" style={{ color: 'var(--color-alert-dark)', fontSize: '0.8rem' }} onClick={(event) => { event.preventDefault(); handleVerifyEmail() }}>{t("ecommerce.myaccount.email-not-verified-short-message-link")}</a>}
                    {emailSentMessage && <div className="w-100 emailSend--success message--success text-bold">{t("ecommerce.myaccount.resent-verify-email-message")}</div>}</p>
                </Property>
                <Property label={t("ecommerce.personalinfo.name")} item={userData.firstName}/>
                <Property label={t("ecommerce.personalinfo.lastname")} item={userData.lastName}/>
                <Property label={t("ecommerce.personalinfo.birthdate")} item={userData.dateOfBirth}/>
                {userData.salutation && <Property label={t("ecommerce.personalinfo.gender")} item={gender}/>}
                <>
                  {["DE"].includes(PROJECT_COUNTRY) && (
                    <>
                      <Property label={t("ecommerce.personalinfo.profiling")} item={userData?.custom?.fields?.consentProfiling ? t("ecommerce.personalinfo.consent-profiling-yes") : t("ecommerce.personalinfo.consent-profiling-no")}/>
                    </>
                  )}
                </>
              </div>
              <div className="col-md-2 ">
                <Button label={t("ecommerce.common.edit")} className="secondary" onClick={() => setFormEditOpen(true)}/>
              </div>

            </div>
          </Box>
        }
        {formEditOpen &&
          <div className="row">
            <div className="col-12 col-md-8">
              <Box className="secondary">
                <EditUserData callback={() => { setFormEditOpen(false) }} />
              </Box>
            </div>
          </div>
        }
      </Box>

      {!PROJECT_SETTINGS?.isKlarnaActive &&
        <div className="row">
          <div className="col-md-6">
            <Box className="secondary">
              <h3>{t("ecommerce.shippingAddress.shipping-address-title")}</h3>
              {selectedAddress && selectedAddressType && selectedAddressType === EAddressType.Shipping &&
                <Box className="secondary unbordered unboxed">
                  <div className="row">
                    <div className="col">
                      <AddAddress
                          ref={addAddressFormRef_shipping}
                          type={selectedAddressType}
                          dataEdit={selectedAddress}
                          callback={() => { setSelectedAddress(null!) }}
                      />
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-4 offset-md-1">
                            <Button onClick={() => handleAddAddressSave(EAddressType.Shipping)} className="primary actionForm" label={t("ecommerce.common.save")}/>
                          </div>
                          <div className="col-md-4 offset-md-2">
                            <Button
                                className="secondary actionForm "
                                label={t("ecommerce.common.cancel")}
                                type="button"
                                onClick={() => setSelectedAddress(null)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              }
              {shippingAddressList?.map((address: any) =>
                <Box className="secondary unbordered unboxed" key={address.id}>
                  <React.Fragment >
                    <Address item={address}/>
                    <div className="row">
                      <div className="col-md-12">
                        <Button label={t("ecommerce.common.edit")} className="simple actionForm" icon="fas fa-pencil-alt" onClick={() => {
                          setSelectedAddressType(EAddressType.Shipping)
                          setSelectedAddress(address)
                          setIsAddressEdit(EAddressType.Shipping, true)
                        }}/>
                        <Button label={t("ecommerce.common.delete")} className="simple actionForm" icon="fas fa-trash" onClick={() => {
                          bannerConsumer.confirm({
                            type: t("ecommerce.personalinfo.confirm-delete-address"), onConfirm: () => {
                              removeAddress(address)
                            }
                          })
                        }}/>
                      </div>
                    </div>
                  </React.Fragment>
                </Box>
              )}
              <div className="row">
                <div className="col-md-2">
                  <Button label={t("ecommerce.shippingAddress.add-shipping-address")} className="simple" icon="fas fa-plus" onClick={() => {
                    setSelectedAddressType(EAddressType.Shipping);
                    setSelectedAddress({country: PROJECT_COUNTRY}!)
                  }}/>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-md-6">
            <Box className="secondary">
              <h3>{t("ecommerce.billingAddress.billing-address-title")}</h3>

              {selectedAddress && selectedAddressType && selectedAddressType === EAddressType.Billing &&
                <Box className="secondary unbordered unboxed">
                  <div className="row">
                    <div className="col">
                      <AddAddress
                          ref={addAddressFormRef_billing}
                          type={selectedAddressType}
                          dataEdit={selectedAddress}
                          callback={() => { setSelectedAddress(null!); }}
                      />
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-4 offset-md-1">
                            <Button onClick={() => handleAddAddressSave(EAddressType.Billing)} className="primary actionForm" label={t("ecommerce.common.save")}/>
                          </div>
                          <div className="col-md-4 offset-md-2">
                            <Button
                                className="secondary actionForm "
                                label={t("ecommerce.common.cancel")}
                                type="button"
                                onClick={() => setSelectedAddress(null)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              }
              {billingAddressList?.map((address: any, i) =>
                <Box className="secondary unbordered unboxed" key={address.id}>
                  <React.Fragment key={i}>
                    <Address item={address}/>
                    <div className="row">
                      <div className="col-md-12">
                        <Button label={t("ecommerce.common.edit")} className="simple actionForm" icon="fas fa-pencil-alt" onClick={() => {
                          setSelectedAddressType(EAddressType.Billing)
                          setSelectedAddress(address)
                          setIsAddressEdit(EAddressType.Billing, true)
                        }}/>
                        <Button label={t("ecommerce.common.delete")} className="simple actionForm" icon="fas fa-trash" onClick={() => {
                          bannerConsumer.confirm({
                            type: t("ecommerce.personalinfo.confirm-delete-address"), onConfirm: () => {
                              removeAddress(address)
                            }
                          })
                        }}/>
                      </div>
                    </div>
                  </React.Fragment>
                </Box>
              )}


              <div className="row">
                <div className="col-md-2">
                  <Button label={t("ecommerce.billingAddress.add-billing-address")} className="simple" icon="fas fa-plus" onClick={() => {
                    setSelectedAddressType(EAddressType.Billing);
                    setSelectedAddress({country: PROJECT_COUNTRY}!)
                  }}/>
                </div>
              </div>

            </Box>
          </div>
        </div>
      }
    </StyledUser>
  );
};


