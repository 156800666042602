import * as React from "react";
import ReactDOM from "react-dom";
import {parsePrice} from "../../common/constants";
import {GeneralContext} from "../../core/general.context";
import {CartProduct} from "./cart.product.component";
import {LabelContext} from "../../core/label.context";
import {BRX_BASE_URL, PROJECT_LANGUAGE} from "../../core/APIUtils";
import {CartContext} from "../../core/cart.context";
import {Button} from "../button/button.component";

export const CartLayer = (props: any) => {
    const {t} = React.useContext(LabelContext);
    const {cart} = React.useContext(GeneralContext);
    const {productsPrices, isEmptyCart, numberOfProducts} = React.useContext(CartContext);
    const useOutsideClick = (callback: () => void) => {
        const ref = React.useRef<HTMLDivElement>(null);

        React.useEffect(() => {
          const handleClick = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            } else {
                event.stopPropagation();
            }
          };
      
          document.addEventListener('click', handleClick, false);
      
          return () => {
            document.removeEventListener('click', handleClick, false);
          };
        }, [ref]);
      
        return ref;
    };
   
    
    const handleClickOutside = () => { 
        const btnCartLayer: any = document.querySelector(".desktop-menu .reactPlaceholder_cartLayer .header_btn-closeLayer");
        const cartLayerActive = document.querySelector(".desktop-menu .reactPlaceholder_cartLayer_btn.open");

        //if cartlayer is active close it
        cartLayerActive && setTimeout(() => {
            btnCartLayer.click();
        }, 300)
    };

    const ref= useOutsideClick(handleClickOutside);

    let bundlesInCart: string[] = []

    return (
  

        <div ref={props.layerNumber === 0 ? ref : undefined} className="cartLayer-container header_layer-container">
            <div className="header_layer-title">
                <span className="title-text">{t("ecommerce.cart.layer.title")}</span> 
                <span className="title-count">{numberOfProducts} {(numberOfProducts === 1) ? t("ecommerce.checkout.productInCart") : t("ecommerce.checkout.productsInCart")}</span>
            </div>

            {isEmptyCart ?
                <div className="emptyCart">
                    <div className="message">{t("ecommerce.cart.empty")}</div>
                </div>
                : ////// else
                <>
                    <div className="header_layer-body">
                        <div className="cartProduct-list">
                            {cart.lineItems.map((item, i) => {
                                /*const relatedServices = item.variant.attributes.find(attr => attr.name === "related-services");
                                const selectedServices = cart.lineItems.filter(item2 => item2.custom?.fields?.relatedLineItem?.id === item.productId)
                                const title = item.name[PROJECT_LANGUAGE];*/

                                let relatedServices = [{
                                    "sku": item.productKey,
                                    "productId": item.productId,
                                    "value": item.variant.attributes.find(attr => attr.name === "related-services")?.value
                                }]

                                let selectedServices = cart.lineItems.filter(item2 => item2.custom?.fields?.relatedLineItem?.id === item.productId)
                                let title = item.name[PROJECT_LANGUAGE];
                                let displayItem = true
                                let bundleKey = ""
                                if (item.custom?.fields["lineitem-bundle"]) {
                                    if (!bundlesInCart.includes(item.custom.fields["lineitem-bundle"])) {
                                        bundleKey = item.custom.fields["lineitem-bundle"]
                                        title = bundleKey
                                        bundlesInCart.push(bundleKey)
                                        let relatedServicesBundle: any = []
                                        // RelatedService per tutti prodotti del bundle
                                        cart.lineItems.filter(
                                            (_lineItem: any) => _lineItem.custom?.fields?.["lineitem-bundle"] === bundleKey).map(
                                            (__lineItem: any) => {
                                                let _relatedServicesBundle = __lineItem.variant.attributes.find((attr: any) => attr.name === "related-services")
                                                if (_relatedServicesBundle) {
                                                    relatedServicesBundle.push(
                                                        {
                                                            "sku": __lineItem.productKey,
                                                            "productId": __lineItem.productId,
                                                            "value": _relatedServicesBundle?.value
                                                        }
                                                    )
                                                }
                                                // Selected Service
                                                selectedServices = [
                                                    ...selectedServices,
                                                    ...cart.lineItems.filter(item2 => item2.custom?.fields?.relatedLineItem?.id === __lineItem.productId)
                                                ]
                                            }
                                        )
                                        relatedServices = relatedServicesBundle
                                    } else {
                                        displayItem = false
                                    }
                                }


                                if (item.productType.obj?.key === "services" || !displayItem)
                                    return null

                                if (item.productType.obj?.key === "services")
                                    return null
                                return (
                                    <CartProduct
                                        isLayer={true}
                                        item={item}
                                        displayItem={displayItem}
                                        bundleKey={bundleKey}
                                        key={i}
                                        ID={item.id}
                                        title={title}
                                        productID={item.productId}
                                        name={item.name[PROJECT_LANGUAGE]}
                                        sku={item.variant.sku}
                                        imgSrc={item.variant.images[0]?.url}
                                        quantity={item.quantity}
                                        relatedServices={relatedServices}
                                        selectedServices={selectedServices}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="cartLayer-footer text-center">
                        <div className="checkout-priceRecap">
                            <div className="priceRecap-prices">
                                <div className="priceRecap-label">{t("ecommerce.checkout.recap.subtotal")}:</div>
                                <div className="prices">
                                    {productsPrices.totalPriceDiscounted.centAmount !== productsPrices.totalPrice.centAmount &&
                                    <div
                                        className="price price--strikethrough">{parsePrice(productsPrices.totalPrice)}</div>
                                    }
                                    <div
                                        className="price price--discounted">{parsePrice(productsPrices.totalPriceDiscounted)}</div>
                                </div>
                            </div>
                        </div>
                        <Button redirectPath={BRX_BASE_URL + '/cart'} className="primary" label={t("ecommerce.cart.layer.gotocart")}/>
                        <div className="checkout-information">
                            <ul>
                                <li className="delivery"><span className="icon"/><span className="label">{t("ecommerce.checkout.info.delivery")}</span></li>
                                <li className="return"><span className="icon"/><span className="label">{t("ecommerce.checkout.info.returns")}</span></li>
                                <li className="securepayment"><span className="icon"/><span className="label">{t("ecommerce.checkout.info.securepayments")}</span></li>
                            </ul>
                        </div>
                    </div>
                    
                </>
            }
        </div>
 
    )

};


export const ToggleCartLayer = () => {
    const {t} = React.useContext(LabelContext);
    const container_btn = document.getElementsByClassName("reactPlaceholder_cartLayer_btn");
    const container = document.getElementsByClassName("reactPlaceholder_cartLayer");
    const {numberOfProducts, isEmptyCart} = React.useContext(CartContext);

    //blocco propagazione per gestire la chiusura del carrello cliccando in quqlsiasi punto fuori dal cart layer
    const handleClickInside = (event: any) => {
        event.stopPropagation();
    }

    if (!container)
        return null;
    else {
        return <>
            {Array.from(container_btn).map((button) =>
                ReactDOM.createPortal(
                    <button className="ec_btn ec_btn-cartLayer" onClick={handleClickInside}>
                        <span className="icon"/>
                        <span className="testo">{t("ecommerce.cart.layer.title")} </span>
                        {!isEmptyCart && <span className="count">{numberOfProducts}</span>}
                    </button>,
                    button
                )
            )}
            {Array.from(container).map((layer, i) => 
                ReactDOM.createPortal(
                    <CartLayer layerNumber={i}/>
                    ,
                    layer
                )
            )}
        </>
    }

};