import * as React from "react";
import ReactDOM from "react-dom";
import { LabelContext } from "../core/label.context";
import { InputElement } from "../components/input/input.component";
import { Button } from "../components/button/button.component";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import {BRX_BASE_URL, calls, PROJECT_KEY, PROJECT_LANGUAGE} from "../core/APIUtils";
import { RESTContext } from "../core/rest.context";
import { GeneralContext } from "../core/general.context";
import { StyledBackInStockForm } from "./backInStockForm.style";
import { StyledBackInStockFormInPage } from "./backInStockForm.style";

/*Back in stock component embedded in listing pages as modal bootstrap with a form inside it*/
export const BackInStockForm = (props: any) => {
  const container = document.getElementById("backInStockReactForm")
  const { t } = React.useContext(LabelContext)
  const { ajax$ } = React.useContext(RESTContext)
  const { userData } = React.useContext(GeneralContext)

  let requiredError = t("ecommerce.form-messagge.required")
  let inputMaxLengthError = t("ecommerce.form-messagge.error-input-max-length")
  let backInStockSuccess = t("ecommerce.form-messagge.backInStock.success")
  let backInStockError = t("ecommerce.form-messagge.backInStock.error")


  type BackInStockValue = {
    name: string
    email: string
    privacy?: any
    product: {
      sku: string
      name: string
      image: string
    }
  }

  const [skuListSubmitted, setSkuListSubmitted] = React.useState<any>([])
  const [formErrors, setFormErrors] = React.useState(false)
  const schema = yup.object().shape({
    name: yup.string().required(requiredError),
    email: yup.string().email().required(requiredError),
    privacy: yup.boolean().oneOf([true], requiredError).required(requiredError),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<BackInStockValue>({
    resolver: yupResolver(schema),
  })

  React.useEffect(() => {
    if (userData) {
      setValue("email", userData.email)
    }
  }, [setValue, userData])

  React.useEffect(() => {
    if (formErrors) {
      let closeButton = document.querySelector("#backInStock button.close")
      closeButton?.addEventListener("click", () => {
        let skus: any = skuListSubmitted
        const index = skus.indexOf(props.sku, 0);
        if (index > -1) {
          skus.splice(index, 1);
        }
        setSkuListSubmitted(skus)
      })
    }
  }, [formErrors])


  const onSubmit: SubmitHandler<BackInStockValue> = (data) => {
    if (data) {
      const body = {
        userName: data.name,
        userEmail: data.email,
        productSku: props.sku,
        productName: props.name,
        productImage: props.imageUrl,
        projectKey: PROJECT_KEY,
        language: PROJECT_LANGUAGE,
      }

      ajax$(calls.Project.backInStock(), undefined, body).subscribe(
          (_res_order) => {
            let skus: any = skuListSubmitted
            skus.push(props.sku)
            setSkuListSubmitted(skus)
            setFormErrors(false)
            reset()
          },
          (_err) => {
            setFormErrors(true)
          }
      )
    }
  }

  if (!container) return null
  else
    return ReactDOM.createPortal(
      <>
        <div className="modal fade" id="backInStock" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(event) => {reset()}}>
                  <span aria-hidden="true">×</span>
                </button>
                <div className="row">
                  <div className="modal-cover col-lg-6 d-none d-lg-flex align-items-center">
                    <img src={props.imageUrl} className="img-fluid" />
                  </div>
                  <div className="modal-form col-lg-6">
                    <section className="default-section white-section">
                      <div className="row">
                        <div className="eform-component col" id="eform-component">
                          <h4 className="title pb-0">{props.title}</h4>
                          <h6 className="text mb-2">{props.description}</h6>
                            <StyledBackInStockForm>
                            { !skuListSubmitted.includes(props.sku) &&  (
                              <form id="bisForm" onSubmit={handleSubmit(onSubmit)}>
                                <InputElement
                                  externalRegister={register("name")}
                                  name="name"
                                  type="text"
                                  label={t("ecommerce.personalinfo.name")}
                                  placeholder={t("ecommerce.personalinfo.name")}
                                  id={`name`}
                                  errors={errors.name?.message}
                                ></InputElement>
                                <InputElement
                                  externalRegister={register("email")}
                                  name="email"
                                  type="email"
                                  label={t("ecommerce.personalinfo.email")}
                                  placeholder={t("ecommerce.personalinfo.email")}
                                  id={`email`}
                                  errors={errors.email?.message}
                                ></InputElement>

                                <div className="mt-3 checkboxButton">
                                  <input type="checkbox" value="1" {...register("privacy")} name="privacy" id="privacy" />
                                  <label htmlFor="privacy" dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.privacy-flag") }} />
                                  {errors.privacy?.message && <p className="message--alert small">{errors.privacy?.message}</p>}
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Button
                                      className="primary actionForm "
                                      label={t("ecommerce.common.activateNotification")}
                                      type="submit"
                                    ></Button>
                                  </div>
                                </div>
                              </form>
                            )}

                            {skuListSubmitted.includes(props.sku) && !formErrors && (
                              <>
                                <div className="alert alert--success" role="alert">
                                  <i className="fas fa-check pr-3 fa-2x"></i>
                                  <div>{backInStockSuccess}</div>
                                </div>
                              </>
                            )}

                            {skuListSubmitted.includes(props.sku) && formErrors && (
                              <>
                                <div className="alert alert--error" role="alert">
                                  <i className="fas fa-exclamation-circle pr-3"></i>
                                  <div>{backInStockError}</div>
                                </div>
                              </>
                            )}
                          </StyledBackInStockForm>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      container
    )
}

/*Back in stock component embedded product detail page as form*/
export const BackInStockFormProductDetail = (props: any) => {
  const container: any = document.getElementById("reactPlaceholder_backInStockProductDetail");
  const { t } = React.useContext(LabelContext)
  const { ajax$ } = React.useContext(RESTContext)
  const { userData } = React.useContext(GeneralContext)

  let requiredError = t("ecommerce.form-messagge.required")
  let backInStockSuccess = t("ecommerce.form-messagge.backInStock.success")
  let backInStockError = t("ecommerce.form-messagge.backInStock.error")
  let backInStockTitleLabel = t("ecommerce.common.product.backInStockTitlePdp")
  let backInStockSubtitleLabel = t("ecommerce.common.product.backInStockSubtitle")
  let storeLocatorLabel = t("ecommerce.common.product.storeLocatorLabel")
  let storeLocatorCta = t("ecommerce.common.product.storeLocatorCta")

  let dataSku = container?.getAttribute('data-sku')
  let dataName = container?.getAttribute('data-name')
  let dataImage = container?.getAttribute('data-image')
  let storeLocatorUrl = container?.getAttribute('data-store-locator-url')

  const [submittedForm, setSubmittedForm] = React.useState(false)

  type BackInStockValue = {
    email: string
    privacy?: any
    product: {
      sku: string
      name: string
      image: string
    }
  }

  const [formErrors, setFormErrors] = React.useState(false)
  const schema = yup.object().shape({
    email: yup.string().email().required(requiredError),
    privacy: yup.boolean().oneOf([true], requiredError).required(requiredError),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<BackInStockValue>({
    resolver: yupResolver(schema),
  })

  React.useEffect(() => {
    if (userData) {
      setValue("email", userData.email)
    }
  }, [setValue, userData])

  const onSubmit: SubmitHandler<BackInStockValue> = (data) => {
    if (data) {
      const body = {
        userName: "",
        userEmail: data.email,
        productSku: dataSku,
        productName: dataName,
        productImage: dataImage,
        projectKey: PROJECT_KEY,
        language: PROJECT_LANGUAGE,
      }

      ajax$(calls.Project.backInStock(), undefined, body).subscribe(
          (_res_order) => {
            setFormErrors(false)
            setSubmittedForm(true)
            reset()
          },
          (_err) => {
            setFormErrors(true)
          }
      )
    }
  }

  if (!container) return null
  else
    return ReactDOM.createPortal(
        <>
          <StyledBackInStockFormInPage>
            {!submittedForm && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col">
                    <div className="message--alert" dangerouslySetInnerHTML={{ __html:  backInStockTitleLabel }} />
                    <div className="message" dangerouslySetInnerHTML={{ __html:  backInStockSubtitleLabel }} />
                  </div>
                </div>
                <div className="row align-items-end">
                  <div className="col-md-8 input-mail">
                    <InputElement
                        externalRegister={register("email")}
                        name="email"
                        type="email"
                        label={t("ecommerce.backInStock.email")}
                        placeholder={t("ecommerce.personalinfo.email")}
                        id={`email`}
                        errors={errors.email?.message}
                        className="noFloatLabel"
                    ></InputElement>
                  </div>
                  <div className="col-md-4 input-btn">
                    <Button
                        className="primary actionForm mb-3"
                        label={t("ecommerce.common.activateNotificationSmall")}
                        type="submit"
                    ></Button>
                  </div>
                </div>
                <div className="checkboxButton">
                  <input type="checkbox" value="1" {...register("privacy")} name="privacy" id="privacy" />
                  <label htmlFor="privacy" dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.privacy-flag") }} />
                  {errors.privacy?.message && <p className="message--alert">{errors.privacy?.message}</p>}
                </div>
                {storeLocatorUrl != null && storeLocatorUrl !== "" && storeLocatorCta != null && storeLocatorCta !== "" &&
                  <div className="row">
                    <div className="col">
                      <label dangerouslySetInnerHTML={{__html: storeLocatorLabel}}/>&nbsp;
                      <a href={BRX_BASE_URL + storeLocatorUrl}>{storeLocatorCta}</a>
                    </div>
                  </div>
                }
              </form>
            )}

            {submittedForm && !formErrors && (
                <>
                  <div className="alert alert--success" role="alert">
                    <i className="fas fa-check pr-3 fa-2x"></i>
                    <div>{backInStockSuccess}</div>
                  </div>
                </>
            )}

            {submittedForm && formErrors && (
                <>
                  <div className="alert alert--error" role="alert">
                    <i className="fas fa-exclamation-circle pr-3"></i>
                    <div>{backInStockError}</div>
                  </div>
                </>
            )}
          </StyledBackInStockFormInPage>
        </>,
        container
    )
}