import * as React from "react";
import ReactDOM from "react-dom";
import {useEffect} from "react"
import {calls, PROJECT_COUNTRY, PROJECT_KEY} from "../core/APIUtils"
import {RESTContext} from "../core/rest.context"
import {ThankYouMessage} from "./CheckoutPage";
import {getAnalyticsProducts, parsePrice} from "../common/constants";
import {GeneralContext} from "../core/general.context";

export const PayFastConfirmationPage = (_props: any) => {
    const container = document.getElementById("reactPlaceholder_payfast-confirmation")
    const { ajax$, ajaxMiddleware$, token } = React.useContext(RESTContext)
    const { userData } = React.useContext(GeneralContext)
    const [payFastOrder, setPayFastOrder] = React.useState<any>()
    const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

    useEffect(() => {
        let params: URLSearchParams = new URLSearchParams(window.location.search)
        if (params?.get("pfcid")) {
            const pfcid: string = params?.get("pfcid") || ""

            const body = {
                cartId: pfcid,
                projectKey: PROJECT_KEY,
                authToken: token?.access_token
            }
            ajaxMiddleware$(calls.Project.getOrderFromCartId(), undefined, body)
                .subscribe(
                    res => {
                        if(res.code === 200 && res.obj){
                            setPayFastOrder(res.obj)
                        }else {
                            ajax$(calls.Project.getMyCartById(pfcid))
                                .subscribe(
                                    res => {
                                        setPayFastOrder(res)
                                    }
                                )
                        }
                    }
                )
        }
    }, [])

    React.useEffect(() => {
        if (payFastOrder) {
            dataLayer.push({
                pageLang: `${analytics?.currentLang || ""}`,
                pageType: `${payFastOrder ? "purchase" : analytics?.pageType}`,
                visitorId: `${payFastOrder?.id}`,
                visitorLoginState: `${payFastOrder?.id ? "logged-in" : "not-logged"}`,
                visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
                visitorEmail: payFastOrder.customerEmail
            })

            dataLayer.push({
                event: "checkoutOption",
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 2,
                            option: [
                                `${payFastOrder?.shippingInfo?.shippingMethodName} - ${payFastOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
                            ],
                        },
                    },
                },
            })

            const {lineItems} = payFastOrder

            dataLayer.push({
                pageType: "purchase",
                visitorId: `${userData?.id}`,
            })

            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currencyCode: `${payFastOrder?.totalPrice?.currencyCode}`,
                    purchase: {
                        actionField: {
                            id: `${payFastOrder?.orderNumber}`,
                            affiliation: 'Online store',
                            revenue: `${parsePrice(payFastOrder?.totalPrice, true)}`,
                            tax: `${parsePrice(payFastOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
                            revenueNotax: `${parsePrice(payFastOrder?.taxedPrice?.totalNet, true)}`,
                            shipping: `${parsePrice(payFastOrder?.shippingInfo?.price, true)}`,
                            coupon: `${payFastOrder?.discountCodes?.map((item: any) => {
                                return item?.discountCode?.obj?.code
                            }).toString()}`,
                            email: `${["DE"].includes(PROJECT_COUNTRY) && userData?.email}`,
                            country: `${payFastOrder?.shippingAddress?.country}`,
                            postalCode: `${payFastOrder?.shippingAddress?.postalCode}`,
                        },
                        products: getAnalyticsProducts(lineItems),
                    }
                }
            });
        } else {
            dataLayer.push({
                'pageLang': `${analytics?.currentLang}`,
                'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
                'visitorId': `${userData?.id}`,
                'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
                'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
            });
        }
    }, [payFastOrder])

    if (!container)
        return null
    else {
        return ReactDOM.createPortal(
            <>
                {!payFastOrder && <div className="isLoading" />}
                {payFastOrder && <ThankYouMessage currentOrder={payFastOrder} />}
            </>
            , container
        )
    }
};

