import * as React from "react";
import ReactDOM from "react-dom";
import { LabelContext } from "../../core/label.context";
import { Box } from "../box/box.component";
import { Button } from "../button/button.component";
import { InputElement } from "../input/input.component";
import { StyledSignup } from "./signup.style";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { inputMaxLength, regexPwdValidation } from "../../common/constants";
import { UserContext } from "../../core/user.context";
import { BRX_LOCALE, PROJECT_COUNTRY } from "../../core/APIUtils"

type SignupValue = {
  email: string,
  repeatEmail: string,
  password: string,
  privacy?: any,
  newsletter?: any,
  profiling?: any
}

export const Signup = (props: any) => {
  const { className } = props
  const { signup } = React.useContext(UserContext)
  const { t } = React.useContext(LabelContext)

  let requiredError = t("ecommerce.form-messagge.required")
  let invalidEmail = t("ecommerce.form-messagge.email-invalid")
  let emailNotMatchError = t("ecommerce.form-messagge.email-must-match")
  let passwordInvalidError = t("ecommerce.form-messagge.password-invalid")

  let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

  const schema = yup.object().shape({
    email: yup.string().matches(emailRegex, invalidEmail).required(requiredError).trim(),
    repeatEmail: yup.string().when({
      is: () => (!["DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY)),
      then: yup.string().matches(emailRegex, invalidEmail).oneOf([yup.ref('email')], emailNotMatchError).required(requiredError).trim()
    }),
    password: yup.string().max(inputMaxLength).required(requiredError).trim().matches(regexPwdValidation, passwordInvalidError),
    privacy: yup.bool().required(requiredError).oneOf([true], requiredError),
    newsletter: yup.bool().oneOf([true, false]),
    profiling: yup.bool().when({
      is: () => ["DE"].includes(PROJECT_COUNTRY),
      then: yup.bool().oneOf([true, false])
    }),
  });

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const customerEmail = params.get("customer-email");

    if (customerEmail) {
      setValue("email", customerEmail);
      setValue("repeatEmail", customerEmail);
    }
  }, []);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<SignupValue>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<SignupValue> = data => {
    signup(data);
  }

  return (
      <StyledSignup className={`${className} cmp_signup`}>
        <Box className="unbordered secondary">
          <div className="centerBlocks">
            <div className="text-center"/>

            <form onSubmit={handleSubmit(onSubmit)}>
              <InputElement
                  label={t("ecommerce.signup.email")}
                  placeholder={t("ecommerce.signup.email")}
                  id="email"
                  externalRegister={register("email")}
                  errors={errors.email?.message}
              />
              {!["DK", "NO", "SE", "FI"].includes(PROJECT_COUNTRY) && (
                  <InputElement
                      label={t("ecommerce.signup.confirm-email")}
                      placeholder={t("ecommerce.signup.confirm-email")}
                      id="repeatEmail"
                      externalRegister={register("repeatEmail")}
                      errors={errors.repeatEmail?.message}
                  />
              )}
              <InputElement
                  type="password"
                  label={t("ecommerce.signup.password")}
                  placeholder={t("ecommerce.signup.password")}
                  id="password"
                  externalRegister={register("password")}
                  errors={errors.password?.message}
              />

              <div className="checkboxButton">
                <>
                  <input type="checkbox" defaultChecked={["DE"].includes(PROJECT_COUNTRY)} value="1" {...register("privacy")} name="privacy" id="privacy" />{" "}
                  <label htmlFor="privacy" dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.privacy-flag") }} />{" "}
                </>
                {errors.privacy?.message && <p className="message--alert small">{errors.privacy?.message}</p>}
              </div>
              <div className="checkboxButton">
                <>
                  <input type="checkbox" value="1" {...register("newsletter")} name="newsletter" id="newsletter" />{" "}
                  <label
                      htmlFor="newsletter"
                      dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.newsletter-flag") }}
                  />
                </>
              </div>
              {["DE"].includes(PROJECT_COUNTRY) && (
                  <div className="checkboxButton">
                    <>
                      <input type="checkbox" value="1" {...register("profiling")} name="profiling" id="profiling" />{" "}
                      <label
                          htmlFor="profiling"
                          dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.profiling-flag") }}
                      />
                    </>
                  </div>
              )}
              <Button
                  className="primary mt-4 mx-auto d-block"
                  label={t("ecommerce.signup.signup-button")}
                  type="submit"
              />
            </form>
          </div>
        </Box>
      </StyledSignup>
  )
}
