import * as React from "react"
import {GeneralContext} from "../../core/general.context"
import {CartContext} from "../../core/cart.context"
import {LabelContext} from "../../core/label.context"
import {CheckoutContext} from "../../core/checkout.context"
import {Authentication} from "../login/authentication"
import {Box} from "../box/box.component"
import {
    BRX_ASSETS_URL,
    CHECKOUT_PAYMENT_LOGOS,
    IS_GIFT_CARD_ACTIVE,
    PROJECT_COUNTRY,
    PROJECT_SETTINGS,
} from "../../core/APIUtils"
import {Button} from "../button/button.component"
import {getBrowserInfo} from "../../common/constants"
import {Property} from "../elements/property/property.component"
import {InputElement} from "../input/input.component"
import * as yup from "yup"
import {SubmitHandler, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import SelectMultiOptions from "../elements/select/SelectMultiOptions"
import styled from "styled-components"
import {UserContext} from "../../core/user.context"
import {AWSlogger} from "../../core/rest.context"
import {useEffect} from "react"

const PaymentMethodDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const KlarnaCheckoutStep1 = (props: any) => {
    const authenticationFormRef = React.useRef<any>(null)
    const { userData } = React.useContext(GeneralContext)

    const { t } = React.useContext(LabelContext)
    const {
        shippingType,
        privacy, setPrivacy,
        nlConsentValue,
        commercial, setCommercial, focusPrivacy,isMandatoryFieldsFlagged,
        useShippingAddressAsBilling, makeSignUp,
    } = React.useContext(CheckoutContext)
    const {setCheckoutStep} = React.useContext(CheckoutContext)
    const {signupFromKlarnaCheckout, loginAsGuestFromCheckout} = React.useContext(UserContext)

    const goToStep2 = async () => {
        if(!isMandatoryFieldsFlagged()){
            return
        }

        const isAuthenticationFormValid = await authenticationFormRef.current?.authenticationFormValidate()

        if(isAuthenticationFormValid || userData){
            const authenticationFormData = await authenticationFormRef.current?.authenticationFormGetData()

            try{
                if(userData){
                    setCheckoutStep(2)
                }else{
                    if(makeSignUp){
                        signupFromKlarnaCheckout(
                            authenticationFormData.email,
                            authenticationFormData.password,
                            nlConsentValue,
                        ).subscribe(
                            () => {
                                authenticationFormRef.current?.authenticationFormReset()
                                setCheckoutStep(2)
                            },
                            (err: any) => {
                                console.error('Errore durante la registrazione:', err)
                                AWSlogger({
                                    type: "error",
                                    action: "signupFromCheckout",
                                    email: authenticationFormData.email,
                                    shippingType: shippingType,
                                    browserInfo: getBrowserInfo(),
                                    errorMessage: err
                                })
                            }
                        );


                    } else {
                        loginAsGuestFromCheckout(authenticationFormData.email, null, null, useShippingAddressAsBilling, shippingType, true)
                            .subscribe(
                                () => {
                                    authenticationFormRef.current?.authenticationFormReset()
                                    setCheckoutStep(2)
                                },
                                (err: any) => {
                                    console.error('Errore durante il login:', err)
                                    AWSlogger({
                                        type: "error",
                                        action: "loginAsGuestFromCheckout",
                                        email: authenticationFormData.email,
                                        shippingType: shippingType,
                                        browserInfo: getBrowserInfo(),
                                        errorMessage: err
                                    })
                                }
                            );
                    }
                }
            }catch (e){
                AWSlogger({
                    type: "error",
                    action: "goToStep2",
                    browserInfo: getBrowserInfo(),
                    errorMessage: e
                })
            }
        } else {
            return
        }

    }

    const step1Validation = () => {
        goToStep2().then(() => {
            const element = document.querySelector(".message--alert") || document.querySelector(".focus");
            if (element) {          
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }else{
                window.scrollTo({top: 0, left: 0, behavior: "smooth"})
            }
        })
    }

    return (
        <>
        {!userData &&
            <Authentication ref={authenticationFormRef}/>
        }

        <div className="component">
            <Box className="secondary compact">
                <div className="component">
                    <ul>
                        <li key="privacy" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                            <div className="d-flex align-items-center mb-1">
                                <input
                                    type="checkbox"
                                    checked={privacy}
                                    value="1"
                                    name="privacy"
                                    id="privacy"
                                    onClick={(event: any) => {
                                        setPrivacy(event.target?.checked)
                                    }}
                                />
                                <label
                                    htmlFor="privacy"
                                    dangerouslySetInnerHTML={{ __html: t("ecommerce.checkout.privacy-check-description") }}
                                />
                                <span className="pr-1">*</span>
                            </div>
                        </li>
                        <li key="commercial" className={`checkboxButton ${focusPrivacy && "focus"}`}>
                            <div className="d-flex align-items-center mb-1">
                                <input
                                    type="checkbox"
                                    checked={commercial}
                                    value="1"
                                    name="commercial"
                                    id="commercial"
                                    onClick={(event: any) => {
                                        setCommercial(event.target?.checked)
                                    }}
                                />
                                <label htmlFor="commercial">
                                    <span dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.commercial-check-description")}}></span>
                                    <span className="pr-1">*</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </Box>
        </div>

        <button className="btn-checkout" onClick={step1Validation}>{t("ecommerce.checkout.go-to-step-2")}</button>
        </>
    )
}

export const KlarnaCheckoutStep2 = (props: any) => {
    const { cart, giftCards, klarna } = React.useContext(GeneralContext)
    const {
        checkIsCartChange,
        isCartChanged,
        setNoteOnCart,
        processingOrder,
        populateGiftCardRecipientInfo,
        differenceBy,
        setApplyGiftCardToCart,
        setRemoveGiftCardFromCart,
        selectedGiftcards,
        setSelectedGiftcards,
        setRemoveAllGiftCardFromCart,
        loadGiftCards, getKlarna
    } = React.useContext(CartContext)
    const { t } = React.useContext(LabelContext)
    const {
        setClickedPay,
        enabledGiftCardEditButton, setEnabledGiftCardEditButton,
        giftcardInsideCard, setGiftcardInsideCart,
        hasGiftCards, setHasGiftCards,
        showGiftCardSelect, setShowGiftCardSelect,
        closeOrderWithGiftCard,
        isMandatoryFieldsFlagged
    } = React.useContext(CheckoutContext)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');

    let requiredError = t("ecommerce.form-messagge.required");
    let invalidEmail = t("ecommerce.form-messagge.email-invalid")
    let confirmEmailNotMatchError = t("ecommerce.form-messagge.email-must-match")
    const giftCardItemSchema = yup.object().shape({
        value: yup.object().shape({
            name: yup.string().required(requiredError),
            surname: yup.string().required(requiredError),
            email: yup.string().email(invalidEmail).required(requiredError),
            confirmEmail: yup.string().email(invalidEmail).required(requiredError).test(
                'email-match',
                confirmEmailNotMatchError,
                function(value) {
                    const { email } = this.parent;
                    return email === value;
                }
            ),
        })
    });

    const giftCardSchema = yup.object().shape({
        "gift-card": yup.lazy(objectShape => {
            const obj: any = {};
            Object.keys(objectShape).forEach(key => {
                obj[key] = yup.array().of(giftCardItemSchema);
            });
            return yup.object().shape(obj);
        })
    });

    //to debuggin form console.log(watch())
    const {register, formState: {errors}, handleSubmit, reset, watch } = useForm<any>({
        resolver: yupResolver(giftCardSchema),
        mode: "onSubmit",
        defaultValues: {
            "gift-card": {}
        }
    });

    let options: any[] = []
    if (giftCards) {
        giftCards.forEach((giftCard: any) => {
            options.push({ value: giftCard?.serialNumber.toString(), label: giftCard?.giftCardName, residual: giftCard?.residualValue })
        })
    }

    const handleGiftCardSelectOnChange = (values: any) => {
        const diffValue = differenceBy(values, selectedGiftcards)
        const wasThereBefore = selectedGiftcards.some((x: any) => x?.value === diffValue?.value)
        //capisco se sto rimuovendo o aggiungeno una giftcard a carrello
        if (wasThereBefore) {
            const removeGiftcard = cart.customLineItems.find((item: any) => (item.slug.split('gift-card-')[1] === diffValue?.value));
            setRemoveGiftCardFromCart(removeGiftcard)
        } else {
            setApplyGiftCardToCart(diffValue)
        }
    };

    const onSubmit: SubmitHandler<any> = (data) => {
        populateGiftCardRecipientInfo(data)
    }

    const loadKlarnaForm = () => {
        setClickedPay(true)
        if (!isMandatoryFieldsFlagged) {
            return
        }
        getKlarna(cart.id)
    }



    useEffect(() => {
        if (cart.customLineItems.length <= 0) {
            setSelectedGiftcards([])
        }

        if(giftCards != null && giftCards.length > 0){
            setHasGiftCards(true)
        }

        const allGiftCardRecipientsFilled = cart.lineItems.every((giftCard) => {
            if (!giftCard?.productType?.obj?.key || giftCard?.productType?.obj?.key !== "GIFTCARD") {
                return true;
            }

            const { quantity } = giftCard;

            if (giftCard?.custom?.fields['recipients-giftcard']) {
                let dataRecipient: any;
                try {
                    dataRecipient = JSON.parse(giftCard?.custom?.fields['recipients-giftcard']);
                } catch (e) {
                    return false;
                }

                return [...Array(quantity)].every((_, i) => {
                    return dataRecipient[i]?.value.name &&
                        dataRecipient[i]?.value.surname &&
                        dataRecipient[i]?.value.email;
                });
            }
        });
        setEnabledGiftCardEditButton(allGiftCardRecipientsFilled)
    }, [cart, giftCards])

    useEffect(() => {
        const data: any = { "gift-card": {}}
        checkIsCartChange(cart)

        //carica le giftcard
        loadGiftCards(true);

        //controlla se ci sono giftcard aggiunte a carrello
        const hasActiveGiftCardsInCart = cart.customLineItems.length > 0
        //mostra la select se ci sono giftcard attive
        if(hasActiveGiftCardsInCart){
            setShowGiftCardSelect(true)
        }

        const selectedGiftCard: any = [];
        cart.customLineItems.map((item: any) => {
            selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
        });
        setSelectedGiftcards(selectedGiftCard)

        const selectedGiftCards = cart.customLineItems
            .filter((item: any) => item.slug && item.slug.includes('gift-card-')) // Filtra solo le customLineItems di tipo giftcard
            .map((item: any) => ({
                value: item.slug.split('gift-card-')[1],
                label: item.name[Object.keys(item.name)[0]],
                residual: Math.abs(item.money.centAmount)
            }));
        setSelectedGiftcards(selectedGiftCards);

        cart.lineItems.map((item: any) => {
            if (item?.productType?.obj?.key === "GIFTCARD") {
                setGiftcardInsideCart(true)

                if (item?.productType?.obj?.key === "GIFTCARD") {
                    let dataRecipient: any;
                    if (item?.custom?.fields['recipients-giftcard']) {
                        try {
                            dataRecipient = JSON.parse(item?.custom?.fields['recipients-giftcard']);
                            data["gift-card"][item.id] = dataRecipient
                        } catch (e) {}
                    }
                }
            }
        })
        reset(data)
    }, [])

    useEffect(() => {
        if (klarna) {
            const script = document.querySelectorAll("#klarna-container script")[0]?.innerHTML
            script && window.eval(script)
        }
    }, [klarna])


    return (
        <div style={{position: "relative"}} className={`row no-gutters ${processingOrder && "processingOrderStyle"}`}>
            <div className="checkout-column-main">
                <div className="wrapper">

                    {/* Inserimento email destinatario per ogni buono regalo */}
                    {cart.lineItems && giftcardInsideCard && IS_GIFT_CARD_ACTIVE &&
                    <>
                        <Box className={`secondary compact`}>
                            <h3 style={{marginBottom: '0'}}>{t("ecommerce.giftcard.checkout.form-section-title")}</h3>
                            <p style={{marginBottom: '20px'}}>{t("ecommerce.giftcard.checkout.form-section-subtitle")}</p>
                            <form action="" id="giftCardForm" onSubmit={handleSubmit(onSubmit)}>
                                {cart.lineItems.map((giftCard) => {
                                    if (giftCard?.productType?.obj?.key === "GIFTCARD") {
                                        const {name, quantity} = giftCard;

                                        return (
                                            <>
                                                {[...Array(quantity)].map((giftcardQta, giftCardQtaIndex) => {

                                                    return (
                                                        <>
                                                            <Box className="secondary unbordered unboxed ">
                                                                <Property
                                                                    label={t("ecommerce.giftcard.checkout.giftCard-title")}
                                                                    item={`${name[Object.keys(name)[0]]} - ${quantity >= 2 ? `${giftCardQtaIndex + 1} / ${quantity.toString()}` : quantity}`}
                                                                />
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <InputElement type='name'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"name"}
                                                                                      label={t("ecommerce.giftcard.name")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.name`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.name?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <InputElement type='surname'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"surname"}
                                                                                      label={t("ecommerce.giftcard.surname")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.surname`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.surname?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <InputElement type='email'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"email"}
                                                                                      label={t("ecommerce.giftcard.email")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.email`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.email?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <InputElement type='confirmEmail'
                                                                                      data-Id={giftCard.id}
                                                                                      data-quantity={giftCardQtaIndex + 1}
                                                                                      data-type={"confirmEmail"}
                                                                                      label={t("ecommerce.giftcard.confirmEmail")}
                                                                                      key={name + giftCard.id + giftCardQtaIndex}
                                                                                      externalRegister={register(`gift-card.${giftCard.id}.${giftCardQtaIndex}.value.confirmEmail`)}
                                                                                      errors={errors["gift-card"]?.[giftCard.id]?.[giftCardQtaIndex]?.value?.confirmEmail?.message}
                                                                                      disabled={enabledGiftCardEditButton}/>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </>)
                                                })}
                                            </>
                                        );
                                    }
                                })}
                                <>
                                    {!enabledGiftCardEditButton && <Button className="primary mt-4 mr-4" label={t("ecommerce.giftcard.checkout.save")} type="submit"/>}
                                    {enabledGiftCardEditButton && <Button className="primary mt-4" label={t("ecommerce.common.edit")} type="button" onClick={() => {setEnabledGiftCardEditButton(false)}}/>}
                                </>
                            </form>
                        </Box>
                    </>
                    }

                    {/* Inserimento nota su ordine */}
                    <div className="component">
                        <Box className={`secondary compact`}>
                            <h3>{t("ecommerce.checkout.note-title")}</h3>
                            <div>{t("ecommerce.checkout.note-description")}</div>
                            <textarea
                                style={{width: "100%"}}
                                className=""
                                onBlur={(e) => {
                                    setNoteOnCart(e.target.value)
                                }}
                            />
                        </Box>
                    </div>

                    {/* se ci sono giftcard da acquistare e il form dei destinatari é stato compilato o se non ci sono giftcard a carrello */}
                    {(enabledGiftCardEditButton || !giftcardInsideCard) &&
                    <>
                        <Box className="boxed-mobile">
                            <span className="text-bold">{t("ecommerce.checkout.deliveryInfo")}</span>
                        </Box>
                        {isCartChanged && (
                            <div className="component">
                                <Box className="secondary compact">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3 className="focus">{t("ecommerce.checkout.cart-changed-title")}</h3>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t("ecommerce.checkout.cart-changed-description"),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        )}
                        {/* isCartChange = true se a carrello é presente un prodotto con disponibilitá negativa (caso particolare per POLONIA che puo avere prodotti con disponibilitá negativa*/}
                        {!isCartChanged && cart.totalPrice.centAmount > 0 && PROJECT_SETTINGS.inventoryMode !== "ReserveOnOrder" && (
                            <div className="component">
                                <Box className="secondary compact">

                                    <h3>{t("ecommerce.checkout.payments-methods-section-title")}</h3>

                                    {IS_GIFT_CARD_ACTIVE && hasGiftCards &&
                                    <>
                                        <div className="checkboxButton">
                                            <input
                                                type="checkbox"
                                                id="toggleDropdown"
                                                name="toggleDropdown"
                                                checked={showGiftCardSelect}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setShowGiftCardSelect(isChecked);

                                                    if (!isChecked) {
                                                        setRemoveAllGiftCardFromCart(cart);
                                                    }
                                                }}
                                            />
                                            <label htmlFor="toggleDropdown">{t("ecommerce.giftcard.load")}</label>
                                        </div>
                                        {!Boolean(cart.discountCodes.length) && showGiftCardSelect &&
                                            <SelectMultiOptions onChange={handleGiftCardSelectOnChange} isMulti options={options} value={selectedGiftcards} className={"col-12 col-md-6 mb-3"}/>
                                        }
                                    </>
                                    }

                                    {cart.totalPrice.centAmount > 0 &&
                                    <>
                                        <div className="text-center">
                                            <PaymentMethodDiv className="container-payment-methods">
                                                <div className="radio-item w-100">
                                                    <input
                                                        type="radio"
                                                        id="klarna-checkbox"
                                                        name="paymentMethod"
                                                        value="klarna"
                                                        checked={selectedPaymentMethod === "klarna"}
                                                        onClick={() => {
                                                            setSelectedPaymentMethod("klarna")
                                                            loadKlarnaForm()
                                                        }}
                                                    />
                                                    <label htmlFor="klarna-checkbox" className="btn-payment-methods icon-cards-visa-mastercard">
                                                        {t("ecommerce.checkout.klarna-button")}
                                                        {CHECKOUT_PAYMENT_LOGOS?.klarna_account?.map((logo: string, i: number) => (
                                                            <img key={i} className={"payment-icon pl-3"} src={`${BRX_ASSETS_URL}/images/icons/payments/footer/${logo}.svg`} alt={logo} />
                                                        ))}
                                                    </label>
                                                </div>
                                            </PaymentMethodDiv>
                                        </div>

                                        <div className="row" id="payments-row">
                                            <div className="col-md-8 offset-md-2">
                                                <div
                                                    id="klarna-container"
                                                    dangerouslySetInnerHTML={{
                                                        __html: klarna?.obj?.html_snippet,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    }

                                    {cart.totalPrice.centAmount === 0 && cart.customLineItems && cart.customLineItems.some(item => item.slug.startsWith('gift-card')) &&
                                    <div className="text-center">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Button
                                                    disabled={true}
                                                    className="ec_btn_primary"
                                                    label={t("ecommerce.checkout.closeOrder")}
                                                    onClick={closeOrderWithGiftCard} />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </Box>
                            </div>
                        )}
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
