import * as React from "react";
import { Box } from "../box/box.component";
import { StyledOrder } from "./order.style";
import { Button } from "../button/button.component";
import { LabelContext } from "../../core/label.context";
import {createPrice, parsePrice} from "../../common/constants";
import { ProductInfo } from "../productInfo/productInfo.component";
import { Address } from "../elements/address/address.component";
import {PROJECT_CURRENCY} from "../../core/APIUtils"

export const Order = (props: any) => {
  const { setActiveItem, activeItem, index, order, hideButton } = props;
  const { billingAddress, shippingAddress, lineItems, customLineItems } = order || {};
  const { t } = React.useContext(LabelContext);
  const [giftCardDetails, setGiftCardDetails] = React.useState([]);

  const expanded = activeItem === index;
  const cls = "order__item " + (expanded ? "item-active" : "");
  let orderDate, orderState;
  if (order) {
    orderDate = new Date(order?.createdAt)
    // Open
    orderState = t('ecommerce.orders.state-confirmed')
    if (order?.shipmentState === "Shipped") {
      // Spedito
      orderState = t('ecommerce.orders.state-shippied')
    }
    if (order?.orderState === "Complete") {
      // Spedito
      orderState = t('ecommerce.orders.state-completed')
    }
    if (order?.orderState === "Cancelled") {
      // Spedito
      orderState = t('ecommerce.orders.state-cancelled')
    }

    //order?.orderState
    // Confermato
    // Annullato
    // Completato
  }

  let productsList = lineItems.filter((p: any) => p.productType.obj?.key !== "services")
  let servicesList = lineItems.filter((s: any) => s.productType.obj?.key === "services")

  React.useEffect(()=> {
    const giftCardDetails = customLineItems
        .filter((c: any) => c.slug && c.slug.startsWith("gift-card"))
        .map((item: any) => {
          const amount = Math.abs(item.money.centAmount);
          const serialNumber = item.slug.split("gift-card-")[1];
          return {
            amount: amount,
            serialNumber: serialNumber
          };
        });
    setGiftCardDetails(giftCardDetails)
  },[])



  return (
    <StyledOrder className="cmp_order">
      <Box className={`secondary ${cls}`}>
        <div className={`row ${cls}`}>
          <div className="col-md-10">
            <div className="item__head">
              <div>{t("ecommerce.orders.order")} {t("ecommerce.orders.number")}<b>{order?.orderNumber} - {orderState}</b></div>
              <div>{t("ecommerce.orders.orderDate")}: {orderDate?.toLocaleDateString()}</div>
              <div>{t("ecommerce.orders.orderAmount")}: {parsePrice(order?.totalPrice)}</div>
              {giftCardDetails && giftCardDetails.length > 0 &&
                giftCardDetails.map((giftcard: any) => (
                    <div>{t("ecommerce.orders.giftcardAmount")}: {parsePrice(createPrice(giftcard.amount, PROJECT_CURRENCY))} ({t("ecommerce.orders.giftcardSerial")} : {giftcard.serialNumber})</div>
                ))
              }
            </div>
          </div>
          {!hideButton &&
            <div className="col-md-2 ">
              <Button
                  className="secondary"
                  label={expanded ? t('ecommerce.orders.btn-hide-details') : t('ecommerce.orders.btn-show-details')}
                  onClick={() => index === activeItem ? setActiveItem(null) : setActiveItem(index)}
              />
            </div>
          }
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="item__body">
              <div className="shippingAddress">
                <br />
                <h4>{t("ecommerce.orders.shippingAddress")}:</h4>
                <Address compact={true} item={shippingAddress}/>
              </div>
              <p/>
              <div className="paymentMethod">
                <br />
                <h4>{t("ecommerce.orders.billingAddress")}:</h4>
                <Address compact={true} item={billingAddress}/>
              </div>


              <p/>
              <div className="items">
                <br />
                <h4>{t("ecommerce.orders.products")}</h4>
                <div className="col-md-4 col-lg-6 col-xl-8">
                  {productsList?.map((item: any) => {
                    return (
                      <Box className="secondary" key={item.id}>
                        <ProductInfo item={item} services={servicesList} callerComponent={"myorders"}/>
                      </Box>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </StyledOrder >
  );
};
