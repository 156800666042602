import styled from "styled-components"

export const StyledButton = styled.button`
  line-height: 1.5;
  white-space: nowrap;
  width: 100%;
  min-width: fit-content;
  max-width: 350px;
  @media (max-width: 768px) {
    width: 100%;
  }
  margin-top: 2px;
  margin-bottom: 20px;
  &.actionForm {
    margin-top: 35px;
  }
  &.fullwidth {
    max-width: 100%;
  }
  font-family: Raleway, sans-serif;
  &.simple {
    padding: 0 0 0 24px;
    position: relative;
    border: none;
    background-color: #fff;

    color: #797979;
    width: auto;
    margin: 10px 60px 0 0;
    i {
      left: 0px;
    }
  }
  &.simple.focus {
    color: #640000;
    font-weight: bold;
  }
  &.simple:hover {
    text-decoration: underline;
  }

  &.primary,
  &.secondary {
    padding: 16px 10px;
    @media (min-width: 1200px) and (max-width: 1367px) {
      padding: 10px;
    }
  }

  &.primary {
    position: relative;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    font-weight: bold;
  }

  &.secondary {
    position: relative;
    background-color: var(--color-secondary);
    color: var(--color-primary);
    border: 1px solid var(--color-light-gray);
  }

  i {
    position: absolute;
    display: block;
    //padding-right: 50px;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    &.fa-spin {
      top: 35%;
    }
  }

  &.color--alert {
    background-color: #640000;
    color: var(--color-secondary);
  }
  &:active,
  &:focus {
    outline: 0;
  }
  &.icon-cards-visa-mastercard {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/visa-mastercard.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-paypal {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/paypal.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-klarna {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/klarna.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-sepa {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/sepa.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-bancontact {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/bancontact.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-ideal {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/ideal.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-applepay {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/applepay-white.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-payU {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/payU.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-bankTrans {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/bankTransfer.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-payByLink {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/payLater.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
  &.icon-cards-buybox {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/buybox.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }&.icon-cards-payfast {
    &:after {
      background-image: url(../webfiles/latest/images/icons/paymentMethods/payfast.svg);
      height: 15px;
      content: "";
      display: block;
      width: 100%;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
  }
`
