import styled from "styled-components";

export const StyledBox = styled.div`
  color:var(--color-primary);
  width:100%;
  padding: 30px;
  margin: 0 0 30px 0;
  background-color:var(--color-porcelain);
  a { text-decoration: underline; }
  ul { padding: 0 0 0 0px;}
  li {
    list-style: none;    
  }
  li>input[type="radio"] {margin-right:10px;}

  &.secondary{
    border-bottom: 1px solid #B6B6B6;
    background-color:unset;
  }

  &.separator{
    margin:50px 0;    
  }

  &.compact{
    padding: 10px 0px;
    margin: 0 0 10px 0;
  }

  &.unboxed{  
    padding:0;
  }

  &.unbordered{  
    border:none;
  }

  &.fixed-height{
    min-height:500px;
    @media (max-width: 768px) {
      min-height:auto;
    }
  }
  @media (max-width: 767px) {
      padding:10;
      border:none;
      &.secondary{
        // border:none;
        background-color:unset;
      }
      &.compact{
        padding:0;
      }
      &.boxed-mobile{
        padding:20px;
        line-height: 1.4rem
      }

  }
  
`;