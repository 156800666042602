import styled from "styled-components";

export const StyledSignup = styled.div`
form{
    width: 100%;
    margin:0;
    .radioGroup__sex{
        .radioButton{
            display: inline-block;
        }
        label{
            margin-right:10px;
        }
        input{
            margin-right:5px;
        }
    }

    .boxed-mobile{
        padding:15% 30px;
    }
    /* label{
        visibility:hidden;
        } */
    input{
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
}
`;