import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { StyledPages } from "./commonPages.style";
import { Box } from "../components/box/box.component";
import { InputElement } from "../components/input/input.component";
import { Button } from "../components/button/button.component";
import { GeneralContext } from "../core/general.context";
import { LabelContext } from "../core/label.context";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserContext } from "../core/user.context";
import { inputMaxLength, regexPwdValidation } from "../common/constants";

type resetPasswordValue = {
    newPassword: string
    newPasswordCheck: string
};


export const ResetPasswordPage = (props: any) => {
    const container = document.getElementById("resetPassword");
    const { resetPasswordState } = React.useContext(GeneralContext);
    const { resetPassword } = React.useContext(UserContext);
    const { t } = React.useContext(LabelContext);

    let requiredError = t("ecommerce.form-messagge.required")
    let passwordInvalidError = t("ecommerce.form-messagge.password-invalid")
    let passwordNotMatchError = t("ecommerce.form-messagge.password-must-match")

    const schema = yup.object().shape({
        newPassword: yup.string().max(inputMaxLength).required(requiredError).trim().matches(regexPwdValidation, passwordInvalidError),
        newPasswordCheck: yup.string().max(inputMaxLength).oneOf([yup.ref('newPassword')], passwordNotMatchError).required(requiredError),
    });

    const { register, handleSubmit, formState: { errors } } = useForm<resetPasswordValue>({
        resolver: yupResolver(schema)
    });

    const onSubmit: SubmitHandler<resetPasswordValue> = data => {


        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');
        if (!token) {
            return;
        }
        resetPassword(token, data.newPassword);
    }


    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle title={t("ecommerce.resetPassword.title")} ></HeaderTitle>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <Box>
                                    <div className="centerBlocks">
                                        <div className="text-center">
                                            {resetPasswordState === 0 &&
                                                <form onSubmit={handleSubmit(onSubmit)} >
                                                    <InputElement type="password" label={t("ecommerce.resetPassword.newPassword")} id={`newPassword`} externalRegister={register("newPassword")} errors={errors.newPassword?.message}></InputElement>
                                                    <InputElement type="password" label={t("ecommerce.resetPassword.newPasswordCheck")} id={`newPasswordCheck`} externalRegister={register("newPasswordCheck")} errors={errors.newPasswordCheck?.message} ></InputElement>
                                                    <Button className="primary mt-4" label={t("ecommerce.common.send")} type="submit" />
                                                </form>
                                            }
                                            {resetPasswordState === 1 &&
                                                <>
                                                    <div className="text-center">
                                                        <h2>{t("ecommerce.resetPassword.password-resetted-title")}</h2>
                                                        <p dangerouslySetInnerHTML={{ __html: t("ecommerce.resetPassword.password-resetted-messagge") }} />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </StyledPages>
            </>
            , container
        )
};
