import * as React from "react"
import { GeneralContext } from "../../core/general.context"
import { Box } from "../box/box.component"
import { StyledOrders } from "./orders.style"
import { LabelContext } from "../../core/label.context"
import { Order } from "./order.component"

export const Orders = (props: any) => {
  const { orders } = React.useContext(GeneralContext)
  const ordersList = [...orders?.results].map((order: string) => order)
  const { t } = React.useContext(LabelContext)

  const [activeItem, setActiveItem] = React.useState(0)

  return (
    <StyledOrders>
      <Box className="unboxed secondary unbordered">
        <h2>{t("ecommerce.myaccount.orders-title")}</h2>
        <div className="sidebar-nav">
          <div className="sidebar-nav-menu">
            {ordersList?.map((order: any) => (
              <Order
                order={order}
                setActiveItem={setActiveItem}
                index={order.id} key={order.id}
                activeItem={activeItem}
              >
              </Order>
            ))}
            {ordersList?.length === 0 &&
              <div className="title_sm text-center">{t("ecommerce.myaccount.no-orders")}</div>
            }
          </div>
        </div>
      </Box>
    </StyledOrders>
  )
}


