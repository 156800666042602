import * as React from "react";
import { IState2 } from "../../common/cart.models";
import { parsePrice, createPrice } from "../../common/constants";
import { LabelContext } from "../../core/label.context";
import { GeneralContext } from "../../core/general.context";
import {
    PROJECT_LANGUAGE,
    MAX_QTY_FOR_PRODUCT,
    PROJECT_SETTINGS,
    BRX_PRODUCTS_URL,
    BRX_BUNDLE_URL,
    calls, PROJECT_CURRENCY
} from "../../core/APIUtils";
import { CartContext } from "../../core/cart.context";
import { BannerContext } from "../../core/banner.context";
import styled from "styled-components";
import { RESTContext } from "../../core/rest.context";
import {
    getBundlePrice,
    getAttributeFromProductProjection,
    getCurrentPrice,
    getDiscountedBundlePriceFromCart
} from "../bundle/utils";


export const CartProduct = (props: any) => {
    const { t } = React.useContext(LabelContext);
    const { cart, userData } = React.useContext(GeneralContext);
    const {
        removeLineItem,
        addLineItem,
        addServiceLineItem,
        removeServiceLineItem,
        moveToWishlist,
        setExternalPopup,
        setGiftPromoId,
    } = React.useContext(CartContext);

    const bannerConsumer = React.useContext(BannerContext);
    const { ajax$ } = React.useContext(RESTContext);
    let isLayer = props.isLayer;

    const [isBundle, setIsBundle] = React.useState(false);
    const [bundleDescription, setBundleDescription] = React.useState();
    const [title, setTitle] = React.useState(props.title);
    const [availableQuantity, setAvailableQuantity] = React.useState(props.item.variant.availability.availableQuantity);
    const [sku, setSku] = React.useState(props.sku);
    const [imgSrc, setImgSrc] = React.useState(props.imgSrc);
    const [bundlePrices, setBundlePrices] = React.useState<any>(null);


    const [productQuantity, setProductQuantity] = React.useState<number>(0)
    const [notDiscountedTotalPrice, setNotDiscountedTotalPrice] = React.useState<any>(null)
    const [productTypeKey, setProductTypeKey] = React.useState("")
    const [displayPromoleLabel, setDisplayPromoleLabel] = React.useState("")
    const [savingPrice, setSavingPrice] = React.useState<any>(null)
    const [totalPrice, setTotalPrice] = React.useState<any>(null)
    const [inPreOrder, setInPreOrder] = React.useState<any>(false)
    const [preOrderLabel, setPreOrderLabel] = React.useState<string>("")
    const [infoNeutralLabel, setInfoNeutralLabel] = React.useState<string>("")
    const [infoPositiveLabel, setInfoPositiveLabel] = React.useState<string>("")
    const [infoWarningLabel, setInfoWarningLabel] = React.useState<string>("")
    const [deeeDescription, setDeeeDescription] = React.useState<string>("")

    const [productUrl, setProductUrl] = React.useState<string>("")


    // Logica bundle
    React.useEffect(() => {


        if (props.bundleKey) {
            ajax$(calls.Project.getProduct(props.bundleKey)).subscribe(
                bundleProjection => {
                    setIsBundle(true)
                    setTitle(bundleProjection.name[PROJECT_LANGUAGE])
                    if (bundleProjection.masterVariant.images.length > 0) {
                        setImgSrc(bundleProjection.masterVariant.images[0].url)
                    }
                    let prices = getBundlePrice(bundleProjection, userData, true, cart)
                    setBundlePrices(prices)

                    //setBundleNotDiscountedTotalPrice(prices.originalPriceFormatted)

                    let productsInBundle = getAttributeFromProductProjection(bundleProjection, "bundle")
                    let _bundleDescription = getAttributeFromProductProjection(bundleProjection, "description-for-cart")
                    if (_bundleDescription) {
                        setBundleDescription(_bundleDescription[PROJECT_LANGUAGE])
                    }
                    let skus = productsInBundle.reduce((skus: string, product: any) => {
                        return skus + product.obj.key + " (" + product.obj.masterData.current.name[PROJECT_LANGUAGE] + ")<br>"
                    }, "")
                    setSku(skus)

                    let _availableQuantity = 0
                    productsInBundle.forEach((product: any) => {
                        if (product.obj.masterData.current.masterVariant.availability.availableQuantity < _availableQuantity || _availableQuantity === 0) {
                            _availableQuantity = product.obj.masterData.current.masterVariant.availability.availableQuantity
                        }
                    })
                    setAvailableQuantity(_availableQuantity)

                    setProductUrl(BRX_BUNDLE_URL + props.bundleKey)
                })
        }

    }, [props.bundleKey, cart])


    let lineItemMode = props.item?.lineItemMode

    React.useEffect(() => {

        if (!isBundle) {
            setProductUrl(BRX_PRODUCTS_URL + props.sku)
        }

        /* I nomi delle variabil rispettano la struttura di CT */

        /*   Prezzo unitario per predotto   */
        let price__value = props.item.price.value

        /*   Prezzo unitario con promozione a prodotto.   Opzionale   */
        let price__discounted = props.item.price.discounted?.value

        /*   Prezzo unitario per promozione a carrello applicata a prezzo scontato.   Opzionale   */
        let discountedPrice = props.item.discountedPrice?.value

        /*   Prezzo totale (non unitario) riga con tutte promozioni applicate*/
        //let totalPrice = props.item.totalPrice
        setTotalPrice(props.item.totalPrice)

        let _notDiscountedTotalPrice = undefined

        let _savingPrice = undefined

        if (price__discounted || discountedPrice) {
            // Prezzo intero senza promozioni
            _notDiscountedTotalPrice = createPrice(price__value.centAmount * props.item.quantity, cart.totalPrice.currencyCode)
            setNotDiscountedTotalPrice(_notDiscountedTotalPrice)
        }

        // Cacolo saving con promozione su prodotto
        if (price__discounted && _notDiscountedTotalPrice) {
            _savingPrice = createPrice(_notDiscountedTotalPrice.centAmount - (price__discounted.centAmount * props.item.quantity), cart.totalPrice.currencyCode)
        }

        // Cacolo saving con promozione su carrello
        if (discountedPrice && _notDiscountedTotalPrice) {
            _savingPrice = createPrice(_notDiscountedTotalPrice?.centAmount - (discountedPrice.centAmount * props.item.quantity), cart.totalPrice.currencyCode)
        }

        setSavingPrice(_savingPrice)

        let _deeeDescription = "";
        const deeeDescriptionResult = props.item.variant.attributes.filter((attribute: any) => attribute.name === 'deee-description')?.map((attribute: any) => attribute.value)
        if (deeeDescriptionResult.length > 0) {
            _deeeDescription = t("ecommerce.common.product.RAEIncluded").replace("[price]", deeeDescriptionResult[0]);
        }
        setDeeeDescription(_deeeDescription)

        let _preOrderLabel = '';
        const _inPreOrder = props.item.variant.attributes.find((attribute: any) => {
            return attribute.name === 'pre-order' && attribute.value === true
        })
        if (_inPreOrder) {
            _preOrderLabel = t("ecommerce.common.product.pre-order-description")
        }
        setInPreOrder(_inPreOrder)
        setPreOrderLabel(_preOrderLabel)

        let _productTypeKey = props.item.productType.obj.key
        setProductTypeKey(_productTypeKey)

        if (props.item.custom && props.item.custom.fields && props.item.custom.fields['parent-promo'] && _productTypeKey === 'EXTERNAL-PRODUCT')
            setGiftPromoId(props.item.custom.fields['parent-promo'])

        let _displayPromoleLabel = "";
        if (props.item.discountedPrice && props.item.discountedPrice.includedDiscounts && props.item.discountedPrice.includedDiscounts.length > 0) {
            props.item.discountedPrice.includedDiscounts.map((discount: any) => {
                if (discount["discount"] && discount["discount"]["obj"]
                    && discount["discount"]["obj"]["custom"] && discount["discount"]["obj"]["custom"]["fields"]
                    && discount["discount"]["obj"]["custom"]["fields"]["displayPromoOnCart"]
                    && discount["discount"]["obj"]["custom"]["fields"]["displayPromoOnCart"] === true
                    && discount["discount"]["obj"]["description"]
                    && discount["discount"]["obj"]["description"][PROJECT_LANGUAGE] !== "") {
                    _displayPromoleLabel = discount["discount"]["obj"]["description"][PROJECT_LANGUAGE]
                }
            })
        }
        setDisplayPromoleLabel(_displayPromoleLabel)

        //let productQuantity = Number(props.quantity)

        setProductQuantity(Number(props.quantity))

        if (!props.bundleKey) {
            setImgSrc(props.imgSrc)
            setSku(props.sku)
            setTitle(props.title)
            setAvailableQuantity(props.item.variant.availability.availableQuantity)
        }

        setInfoPositiveLabel("")
        setInfoNeutralLabel("")
        setInfoWarningLabel("")
        props.item.variant.attributes.map((attribute: any) => {
            attribute.name === "info-positive" && attribute?.value[PROJECT_LANGUAGE] !== undefined && attribute?.value[PROJECT_LANGUAGE] !== "" && setInfoPositiveLabel(attribute.value[PROJECT_LANGUAGE])
            attribute.name === "info-neutral" && attribute?.value[PROJECT_LANGUAGE] !== undefined && attribute?.value[PROJECT_LANGUAGE] !== "" && setInfoNeutralLabel(attribute.value[PROJECT_LANGUAGE])
            attribute.name === "info-warning" && attribute?.value[PROJECT_LANGUAGE] !== undefined && attribute?.value[PROJECT_LANGUAGE] !== "" && setInfoWarningLabel(attribute.value[PROJECT_LANGUAGE])
        })

    }, [props.item, isBundle, cart])


    return (
        <>
            {totalPrice &&
                <StyledCart className="cartProduct row no-gutters">
                    <div key="image" className="cartProduct-image col-3">
                        <a href={['EXTERNAL-PRODUCT', 'GIFTCARD'].includes(productTypeKey) ? 'javascript:void(0)' : `${productUrl}`}>
                            <img src={imgSrc} alt="" />
                        </a>
                    </div>
                    <div key="content" className="cartProduct-details row no-gutters col-9">

                        {displayPromoleLabel !== '' &&
                            <div className="w-100"><span style={{
                                "backgroundColor": "#F8F8F8",
                                "padding": "8px 15px",
                                "textTransform": "uppercase",
                                "display": "inline-block",
                                "textAlign": "left"
                            }}>{displayPromoleLabel}</span></div>
                        }
                        <div className="cartProduct-name col-8">
                            <a href={['EXTERNAL-PRODUCT', 'GIFTCARD'].includes(productTypeKey) ? 'javascript:void(0)' : `${productUrl}`}>{title}</a>
                            <span className="SKU" dangerouslySetInnerHTML={{ __html: sku }} />
                            <span className="SKU">{bundleDescription}</span>
                        </div>

                        <div className="cartProduct-prices prices col-4">
                            {notDiscountedTotalPrice && savingPrice && savingPrice?.centAmount > 0 && productTypeKey !== 'EXTERNAL-PRODUCT' &&
                                <>
                                    {
                                        !isBundle
                                            ?
                                            <>
                                                <div
                                                    className="price price--strikethrough">{parsePrice(notDiscountedTotalPrice)}</div>
                                                <div className="price price--discounted">{parsePrice(totalPrice)}</div>
                                                <div
                                                    className="discount-applied" style={{fontWeight:'400'}}>{t("ecommerce.checkout.saving")} {parsePrice(savingPrice)}</div>
                                            </>
                                            :
                                            (bundlePrices &&
                                            <>
                                                {bundlePrices?.bundlePrice > 0
                                                    ?
                                                    <>
                                                        <div className="price price--strikethrough">{parsePrice(createPrice(bundlePrices?.productsPrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                        <div className="price price--discounted">{parsePrice(createPrice(bundlePrices?.bundlePrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                        <div className="discount-applied" style={{fontWeight: '400'}}>{t("ecommerce.checkout.saving")} {parsePrice(createPrice(bundlePrices?.savingPrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                    </>
                                                    :
                                                    <div className="price price--discounted">{parsePrice(createPrice(bundlePrices?.productsPrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                }
                                            </>)
                                    }
                                </>
                            }
                            {(!savingPrice || savingPrice.centAmount === 0) && productTypeKey !== 'EXTERNAL-PRODUCT' &&
                                <>
                                    {
                                        !isBundle
                                            ?
                                            <>
                                                <div className="price">{parsePrice(totalPrice)}</div>
                                            </>
                                            :
                                            <>
                                                <div className="price price--discounted">{parsePrice(createPrice(bundlePrices?.productsPrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                            </>
                                    }
                                </>
                            }
                        </div>

                        <div className="cartProduct-selectQuantity">
                            <span className="label-qnt">{t("ecommerce.checkout.quantity")}:</span>
                            {(productTypeKey !== 'EXTERNAL-PRODUCT' && lineItemMode !== 'GiftLineItem' && productQuantity > 1) ?
                                <button className="ec_btn ec_btn-decrease" onClick={
                                    () => {
                                        removeLineItem(props.ID, false)
                                    }
                                }>
                                    <span className="icon">-</span>
                                    <span className="testo">{t("ecommerce.checkout.quantityDecrease")}</span>
                                </button>
                                :
                                <button className="invisible ec_btn ec_btn-decrease"><span className="icon">-</span>
                                </button>
                            }
                            <input className="selected-quantity" type="" value={props.quantity} readOnly />
                            {(productTypeKey !== 'EXTERNAL-PRODUCT' && lineItemMode !== 'GiftLineItem') && (((props.quantity < MAX_QTY_FOR_PRODUCT) && (props.quantity < availableQuantity)) || PROJECT_SETTINGS.inventoryMode === "TrackOnly") &&
                                <button className="ec_btn ec_btn-increase" onClick={
                                    () => {
                                        if (props.item.custom?.fields["lineitem-bundle"]) {
                                            addLineItem(props.item.custom?.fields["lineitem-bundle"], true, true)
                                        } else {
                                            addLineItem(props.sku, true, true)
                                        }
                                    }
                                }>
                                    <span className="icon">+</span>
                                    <span className="testo">{t("ecommerce.checkout.quantityIncrease")}</span>
                                </button>
                            }
                            {((props.quantity < MAX_QTY_FOR_PRODUCT) && (props.quantity < availableQuantity)) && (productTypeKey === 'EXTERNAL-PRODUCT' || lineItemMode !== 'GiftLineItem') &&
                                <button className="invisible ec_btn ec_btn-increase"><span className="icon">+</span>
                                </button>
                            }
                        </div>

                        {(lineItemMode !== 'GiftLineItem' || (lineItemMode === 'GiftLineItem' && props.item.variant.availability.availableQuantity === 0)) &&
                            <>
                                <div className="cartProduct-delete">
                                    {productTypeKey !== 'EXTERNAL-PRODUCT' ?
                                        <>
                                            <span style={{cursor:"pointer"}} onClick={() => {
                                                bannerConsumer.confirm({
                                                    type: t("ecommerce.common.delete.confirm"), onConfirm: () => {
                                                        removeLineItem(props.ID, true)
                                                    }
                                                })
                                            }}>{t("ecommerce.common.delete.text")}</span>
                                            <button className="ec_btn ec_btn-delete" onClick={() => {
                                                bannerConsumer.confirm({
                                                    type: t("ecommerce.common.delete.confirm"), onConfirm: () => {
                                                        removeLineItem(props.ID, true)
                                                    }
                                                })
                                            }}>
                                                <span className="icon"/>
                                                <span className="testo">{t("ecommerce.common.delete")}</span>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <span>{t("ecommerce.common.popup-external-promo.changeQuantity")}</span>
                                            <button className="ec_btn ec_btn-edit"
                                                onClick={() => setExternalPopup(true)}>
                                                <span className="icon"/>
                                                <span
                                                    className="testo">{t("ecommerce.common.popup-external-promo.changeQuantity")}</span>
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        {lineItemMode === 'GiftLineItem' &&
                            <div className="col-xl-12 text-bold">{t('ecommerce.checkout.item-free')}</div>
                        }
                        {((props.quantity > MAX_QTY_FOR_PRODUCT) || (props.quantity > props.item.variant.availability.availableQuantity)) && (props.item.variant.availability.availableQuantity > 0) && (
                            PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder" ?
                                <div
                                    className="col-xl-12 focus-info focus-info-small mt-3"
                                    style={{color: 'var(--color-alert-light)'}}>{t('ecommerce.checkout.item-cart-qty-changed')}</div>
                                :
                                <div
                                    className="col-xl-12 focus-info focus-info-small mt-3"
                                    style={{color: 'var(--color-alert-light)'}}>{t('ecommerce.checkout.cart-product-item-will-arrive-late')}</div>
                        )}
                        {props.item.variant.availability.availableQuantity <= 0 && (
                            PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder" ?
                                <div className="col-xl-12 focus-info focus-info-small mt-3">
                                    <div style={{color: 'var(--color-alert-light)'}}>
                                        {t('ecommerce.checkout.item-cart-removed')}
                                    </div>
                                    {/* <a href="#" className="d-inline-block mt-1" onClick={() => moveToWishlist(props.item.id)}>
                                        {t('ecommerce.common.product.addToWishlist')}
                                    </a> */}
                                    <button className="js_addToWishlist btn_addToWishlist text-left mt-2"
                                            onClick={() => moveToWishlist(props.item.id)}>
                                        <span className="icon"/>
                                        <span className="testo">{t('ecommerce.common.product.addToWishlist')}</span>
                                        <span className="testo2">{t("ecommerce.common.product.addedToWishlist")}</span>
                                    </button>
                                </div>
                                :
                                <div className="col-xl-12 focus-info focus-info-small mt-3"
                                        style={{color: 'var(--color-alert-light)'}}>
                                    {t('ecommerce.checkout.cart-product-item-will-arrive-late')}
                                </div>
                        )}
                        {inPreOrder && preOrderLabel !== "" && (
                            <div className="col-xl-12 focus">{preOrderLabel}</div>
                        )}
                        {infoPositiveLabel !== "" &&
                            <div className="col-xl-12 focus-info" style={{ color: 'var(--color-success)' }}>{infoPositiveLabel}</div>
                        }
                        {infoWarningLabel !== "" &&
                            <div className="col-xl-12 focus-info" style={{ color: 'var(--color-alert)' }}>{infoWarningLabel}</div>
                        }
                        {infoNeutralLabel !== "" &&
                            <div className="col-xl-12 focus-info text-bold">{infoNeutralLabel}</div>
                        }
                        <div className="col-xl-12">{deeeDescription}</div>
                    </div>

                    {!isLayer &&
                        <>
                            {props.relatedServices.map(
                                (currentProduct: any, i: any) => {

                                    let titleForServices = isBundle ? currentProduct.sku + ": " + t("ecommerce.checkout.selectServices") : t("ecommerce.checkout.selectServices")

                                    const relatedServices = currentProduct.value?.filter((currentRelatedService: any) => currentRelatedService?.obj?.masterData.published === true);

                                    if (!relatedServices || relatedServices.length === 0) return null;

                                    return <>
                                        <div className="cartProduct-services" key={i}>
                                            <div className="label">{titleForServices}</div>
                                            {currentProduct.value.filter((currentRelatedService: any) => currentRelatedService.obj.masterData.published === true).map((rel: IState2, i: number) => {
                                                let currentServiceLineItems = props.selectedServices.filter((service: any) => {
                                                    return (service.custom?.fields?.relatedLineItem?.id === currentProduct.productId && service.productId === rel.id)
                                                });
                                                let isSelected: boolean;
                                                let price: string;
                                                let priceDiscounted: string;
                                                if (currentServiceLineItems.length > 0) {
                                                    // Riga a carrello
                                                    currentServiceLineItems = currentServiceLineItems[0];
                                                    isSelected = true;
                                                    price = parsePrice(currentServiceLineItems.price.value) || "";

                                                    priceDiscounted = parsePrice(currentServiceLineItems.discountedPrice?.value) || "";
                                                } else {
                                                    // Prodotto
                                                    isSelected = false;
                                                    let _price = getCurrentPrice(rel.obj?.masterData.current.masterVariant.prices, userData)
                                                    price = parsePrice(_price?.value) || "";
                                                    priceDiscounted = parsePrice(_price?.discounted?.value) || "";
                                                }
                                                return (
                                                    <div className="service" key={rel.id + "-" + currentProduct.productId}>
                                                        <div className="service-header checkboxButton">
                                                            <div className="d-flex">
                                                                {isSelected &&
                                                                    <input checked={true} type="checkbox"
                                                                        id={'service_' + rel.id + '_' + currentProduct.productId}
                                                                        name={'service_' + rel.id} key={i}
                                                                        onChange={() => removeServiceLineItem(currentServiceLineItems.id)} />
                                                                }
                                                                {!isSelected &&
                                                                    <input checked={false} type="checkbox"
                                                                        id={'service_' + rel.id + '_' + currentProduct.productId}
                                                                        name={'service_' + rel.id} key={i}
                                                                        onChange={() => addServiceLineItem(rel.obj?.masterData.current.masterVariant.sku, currentProduct.productId)} />
                                                                }
                                                                <label
                                                                    htmlFor={'service_' + rel.id + '_' + currentProduct.productId}>{rel.obj?.masterData.current.name[PROJECT_LANGUAGE]}</label>
                                                                <button className="ec_btn ec_btn-moreInfo"
                                                                    data-toggle="collapse"
                                                                    data-target={'#service_description' + rel.id + '_' + currentProduct.productId}
                                                                    aria-expanded="false"
                                                                    aria-controls={'service_description' + rel.id + '_' + currentProduct.productId}>
                                                                    <span className="icon"/>
                                                                    <span
                                                                        className="testo">{t("ecommerce.checkout.moreInfo")}</span>
                                                                </button>
                                                            </div>
                                                            <div className="service-prices prices">
                                                                {priceDiscounted && (
                                                                    parseInt(priceDiscounted) !== 0 ?
                                                                        <>
                                                                            <div
                                                                                className="price price--strikethrough">{price}</div>
                                                                            <div
                                                                                className="price price--discounted">{priceDiscounted}</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                           
                                                                            <div
                                                                                className="price price--discounted">{t("ecommerce.checkout.free")}</div>
                                                                        </>
                                                                )}
                                                                {!priceDiscounted && (
                                                                    parseInt(price) !== 0 ?
                                                                        <>
                                                                            <div className="price">{price}</div>
                                                                        </>
                                                                        :
                                                                        <div
                                                                            className="price price--discounted">{t("ecommerce.checkout.free")}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="service-description collapse"
                                                            id={'service_description' + rel.id + '_' + currentProduct.productId}
                                                            dangerouslySetInnerHTML={{ __html: rel.obj?.masterData.current.description[PROJECT_LANGUAGE] || "" }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                })
                            }

                        </>
                    }

                </StyledCart>}
        </>
    );
};

export const StyledCart = styled.div`
 .cartProduct-name{
   word-break: break-word;
 } 
 .cartProduct-services .service-prices{
   text-align: right;
   .price{
     &.price--strikethrough{
       margin-right: unset;
     }
     &.price--discounted{
       margin-left: 10px;
     }
   }
 }
`