import * as React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { GeneralContext } from "../core/general.context";
import { StyledPages } from "./commonPages.style";
import { CartContext } from "../core/cart.context";
import { calls } from "../core/APIUtils";
import { RESTContext } from "../core/rest.context";
import { getAnalyticsProducts, parsePrice, redirectTo } from "../common/constants";
import {ThankYouMessage} from "./CheckoutPage";

export const YounitedConfirmationPage = (_props: any) => {
    const [younitedCartId, setYounitedCartId] = useState<string>();
    const [younitedOrder, setYounitedOrder] = useState<any>();
    const container = document.getElementById("reactPlaceholder_younited-confirmation");
    const { t } = React.useContext(LabelContext);
    const { userData, younited } = React.useContext(GeneralContext);
    const { confirmYounitedContract } = React.useContext(CartContext);
    const { ajax$ } = React.useContext(RESTContext);
    const localStorage = (window as any).localStorage || []
    const dataLayer = (window as any).dataLayer || []
    const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

    useEffect(() => {
        let params: URLSearchParams = new URLSearchParams(window.location.search)
        if (params?.get("ypcid")) {
            const sid: string = params?.get("ypcid") || ""
            setYounitedCartId(sid);
        }
    }, [])

    useEffect(() => {
        if (younitedCartId) {
            confirmYounitedContract(younitedCartId)
        }
    }, [younitedCartId])

    useEffect(() => {
        if (younited) {
            if (younited.code > 302){
                redirectTo('checkout?status=error');
            }
            else if (younited?.obj?.ctOrder) {
                setYounitedOrder(younited.obj.ctOrder);
            } else {
                if (younited?.obj?.cartId) {
                    ajax$(calls.Project.getMyCartById(younited?.obj?.cartId))
                        .subscribe(
                            res => {
                                setYounitedOrder(res);
                            }
                        );
                } 
            }
        }
    }, [younited])

    useEffect(() => {
        if (younitedOrder) {
            dataLayer.push({
                pageLang: `${analytics?.currentLang || ""}`,
                pageType: `${younitedOrder ? "purchase" : analytics?.pageType}`,
                visitorId: `${userData?.id}`,
                visitorLoginState: `${userData?.id ? "logged-in" : "not-logged"}`,
                visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
            })

            dataLayer.push({
                event: "checkoutOption",
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 2,
                            option: [
                                `${younitedOrder?.shippingInfo?.shippingMethodName} - ${younitedOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
                            ],
                        },
                    },
                },
            })

            dataLayer.push({
                event: "doofinder_checkout",
                hashid: "event.detail.tag._hashid",

            })

            const { lineItems } = younitedOrder

            dataLayer.push({
                pageType: "purchase",
                visitorId: `${userData?.id}`,
            })
            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currencyCode: `${younitedOrder?.totalPrice?.currencyCode}`,
                    purchase: {
                        actionField: {
                            id: `${younitedOrder?.orderNumber}`,
                            affiliation: 'Online store',
                            revenue: `${parsePrice(younitedOrder?.totalPrice, true)}`,
                            tax: `${parsePrice(younitedOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
                            shipping: `${younitedOrder?.shippingInfo?.price?.centAmount}`,
                            coupon: `${younitedOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.code }).toString()}`,
                        },
                        products: getAnalyticsProducts(lineItems),
                    }
                }
            });
        } else {
            dataLayer.push({
                'pageLang': `${analytics?.currentLang}`,
                'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
                'visitorId': `${userData?.id}`,
                'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
                'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
            });
        }
    }, [younitedOrder])

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
            {!younitedOrder && <div className="isLoading" />}
            {younitedOrder && <ThankYouMessage currentOrder={younitedOrder} />}
            </>
            , container
        )
}
