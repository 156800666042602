import * as React from "react"
import ReactDOM from "react-dom"
import { Checkout } from "../components/checkout/checkout.component"
import { StyledPages } from "./commonPages.style"
import { EShippingType } from "../common/common.models"
import { GeneralContext } from "../core/general.context"
import { LabelContext } from "../core/label.context"
import { PROJECT_COUNTRY, BRX_BASE_URL, PROJECT_SETTINGS, calls } from "../core/APIUtils"
import { HeaderTitle } from "../components/header/title/headerTitle.component"
import { CartContext } from "../core/cart.context"
import { getAnalyticsProducts, parsePrice } from "../common/constants"
import { AWSlogger, RESTContext } from "../core/rest.context";
import { BannerContext } from "../core/banner.context"
import {CustomercareInfoTelephone} from "../components/customercare/customercareInfoTelephone.component";
import {KlarnaCheckout} from "../components/klarnaCheckout/klarnaCheckout.component";


export const CheckoutPage = (_props: any) => {
    const {t} = React.useContext(LabelContext)
    const container = document.getElementById("checkout")
    const {userData, shippingMethods, currentOrder, temporaryOrder, cart} = React.useContext(GeneralContext)
    const {isEmptyCart, resetCartForCheckout, setProcessingOrder} = React.useContext(CartContext)
    const {ajax$} = React.useContext(RESTContext);
    const bannerConsumer = React.useContext(BannerContext);
    const [guest] = React.useState(false)
    const [redirectResult, setRedirectResult] = React.useState("")
    const params = new URLSearchParams(window.location.search)
    const result = params.get("redirectResult")
    const canProceed = Boolean(userData || guest || result)

    const isPickUpAvailable = shippingMethods?.find((store: any) => store.custom?.fields["shipping-type-list"] === EShippingType.Pickup)
    const localStorage = (window as any).localStorage || []
    const dataLayer = (window as any).dataLayer || []
    const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

    const awc = params.get('awc');

    React.useEffect(() => {
        if (result) {
            setRedirectResult(result);
        } else {
            if (container) {
                resetCartForCheckout(null, false);
            }
        }
        if (container) {

            if (canProceed) {

                dataLayer.push({
                    event: 'checkout',
                    ecommerce: {
                        checkout: {
                            actionField: {step: 2},
                            products: getAnalyticsProducts(cart?.lineItems),
                        }
                    }
                });
            } else {
                dataLayer.push({
                    event: 'checkout',
                    ecommerce: {
                        checkout: {
                            actionField: {step: 1},
                            products: getAnalyticsProducts(cart?.lineItems),
                        }
                    }
                });
            }
        }
    }, [container])

    React.useEffect(() => {
    if (container) {
      if (!canProceed) {
        const btns = container.querySelectorAll("button")
        btns.forEach((btn) => {
          btn.addEventListener("click", (event: any) => {
            dataLayer.push({
              event: "checkoutOption",
              ecommerce: {
                checkout_option: {
                  actionField: { step: 1, option: event?.target?.value },
                },
              },
            })
          })
        })
      }
    }
    let params: URLSearchParams = new URLSearchParams(window.location.search)
    if (params?.get("status") && params?.get("status") === 'error') {
      bannerConsumer.show({
        type: t("ecommerce.error.payment-generic-error-title"),
        description: t("ecommerce.error.payment-generic-error-description")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (currentOrder) {
            setProcessingOrder(false)
            dataLayer.push({
            pageLang: `${analytics?.currentLang || ""}`,
            pageType: `${currentOrder ? "purchase" : analytics?.pageType}`,
            visitorId: `${userData?.id}`,
            visitorLoginState: `${userData?.id ? "logged-in" : "not-logged"}`,
            visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
            visitorEmail: currentOrder.customerEmail
          })

            dataLayer.push({
                event: "checkoutOption",
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 2,
                            option: [
                                `${currentOrder?.shippingInfo?.shippingMethodName} - ${currentOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
                            ],
                        },
                    },
                },
            })

            const {lineItems} = currentOrder

            dataLayer.push({
                pageType: "purchase",
                visitorId: `${userData?.id}`,
            })

            // integration So vendus for germany
            let sovendusOrderValue = ""
            if (["DE"].includes(PROJECT_COUNTRY)) {
                sovendusOrderValue = String((((currentOrder?.totalPrice?.centAmount - currentOrder?.shippingInfo?.price?.centAmount) / (1 + currentOrder?.lineItems[0]?.taxRate?.amount)) / 100).toFixed(2));
            }

            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currencyCode: `${currentOrder?.totalPrice?.currencyCode}`,
                    purchase: {
                        actionField: {
                            id: `${currentOrder?.orderNumber}`,
                            affiliation: 'Online store',
                            revenue: `${parsePrice(currentOrder?.totalPrice, true)}`,
                            tax: `${parsePrice(currentOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
                            revenueNotax: `${parsePrice(currentOrder?.taxedPrice?.totalNet, true)}`,
                            shipping: `${parsePrice(currentOrder?.shippingInfo?.price, true)}`,
                            coupon: `${currentOrder?.discountCodes?.map((item: any) => {
                                return item?.discountCode?.obj?.code
                            }).toString()}`,
                            email: `${["DE"].includes(PROJECT_COUNTRY) && userData?.email}`,
                            sovendusOrderValue: `${["DE"].includes(PROJECT_COUNTRY) && sovendusOrderValue}`,
                            country: `${currentOrder?.shippingAddress?.country}`,
                            postalCode: `${currentOrder?.shippingAddress?.postalCode}`,
                        },
                        products: getAnalyticsProducts(lineItems),
                    }
                }
            });


            // integration https://wiki.awin.com/ marketing
            if (["DE"].includes(PROJECT_COUNTRY) && awc) {
                const awinUrl = calls.Project.awinOrderCreated('20891', `${parsePrice(currentOrder?.totalPrice, true)}`, 'aw', 'DEFAULT', 'EUR', `${currentOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.name?.it })}`, `${currentOrder?.orderNumber}`, `0`, awc)
                ajax$(awinUrl).subscribe(
                    res =>
                        AWSlogger({
                            type: "log",
                            action: "awinServiceSuccess",
                            url: awinUrl
                        })
                    ,
                    err => AWSlogger({
                        type: "log",
                        action: "awinServiceError",
                        url: awinUrl
                    })
                );
            }

        } else {
            dataLayer.push({
                'pageLang': `${analytics?.currentLang}`,
                'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
                'visitorId': `${userData?.id}`,
                'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
                'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
            });
        }
    }, [currentOrder])

    if (!container) return null
    else
        return ReactDOM.createPortal(
            <>
                {!currentOrder && !temporaryOrder && (
                    <StyledPages>
                        <div className="container-bootstrap">
                            <div className="row align-items-center pt-5 margin-breadcrumb">
                            <div className="col-12 col-md-6 col-lg-8 order-12 order-md-1">
                                <h1>{t("ecommerce.checkout.page.title")}</h1>
                                <p>{isPickUpAvailable && canProceed ? t("ecommerce.checkout.page.message") : ""}</p>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4 order-1 order-md-1">
                                <CustomercareInfoTelephone />
                            </div>
                            </div>
                        </div>

                        <div className="container-bootstrap">
                            {isEmptyCart ? (
                                <div className="emptyCart">
                                    <div className="message">{t("ecommerce.checkout.empty-cart-title")}</div>
                                    <a href={BRX_BASE_URL}> &lt; {t("ecommerce.checkout.goto-shopping")} </a>
                                </div>
                            ) : (
                                <>
                                    {PROJECT_SETTINGS?.isKlarnaActive ? (
                                        <KlarnaCheckout redirectResult={redirectResult} />
                                    ) : (
                                        <Checkout redirectResult={redirectResult} />
                                    )}
                                </>
                            )}
                        </div>
                    </StyledPages>
                )}
                {currentOrder && <ThankYouMessage currentOrder={currentOrder}/>}
                {temporaryOrder && !currentOrder && <ThankYouMessage currentOrder={temporaryOrder}/>}
            </>,
            container
        )
}

export const ThankYouMessage = (props: any) => {
    const {t} = React.useContext(LabelContext)
    const {userData} = React.useContext(GeneralContext)
    let order = props.currentOrder
    let messageOrderNumberConfirmed: string = ""
    let createAccountMessage: string = ""
    let customerEmail: string = order.customerEmail

    if (order.type === "Order") {
        messageOrderNumberConfirmed = t("ecommerce.checkout.order-confirmed-message-with-order-number")
        messageOrderNumberConfirmed = messageOrderNumberConfirmed.replace(
            "[ORDER_NUMBER]",
            "<b>" + order.orderNumber + "</b>"
        )
    }

    createAccountMessage = t("ecommerce.checkout.create-account-message")
    createAccountMessage = createAccountMessage.replace(
        "[EMAIL]",
        customerEmail ? customerEmail : ""
    )

    React.useEffect(() => {
        const recipeListDiv = document.getElementById('showRecipeList');
        if (recipeListDiv && window.initMasonryPlugin && typeof window.initMasonryPlugin === 'function') {
            recipeListDiv.style.display = 'block';
            window.initMasonryPlugin()
        }

        // Cleanup: nasconde il div quando il componente viene smontato
        return () => {
            if (recipeListDiv) {
                recipeListDiv.style.display = 'none';
            }
        }
    }, [])

    const handleRedirect = () => {
        window.location.href = `${BRX_BASE_URL}/register?customer-email=${customerEmail}`
    }

    const benefit1 = t("ecommerce.checkout.create-account-benefit-1")
    const benefit2 = t("ecommerce.checkout.create-account-benefit-2")
    const benefit3 = t("ecommerce.checkout.create-account-benefit-3")
    const benefit4 = t("ecommerce.checkout.create-account-benefit-4")

    return (
        <StyledPages>
            <HeaderTitle/>
            <div className="container">
                <div className={`row justify-content-center ${userData && 'text-center'}`}>
                    <div className={`col-md-${userData ? '12' : '6'} col-sm-12`}>
                        <h1>{t("ecommerce.checkout.order-confirmed-page-title")}</h1>

                        {order.type === "Order" &&
                            <span dangerouslySetInnerHTML={{__html: messageOrderNumberConfirmed}}/>
                        }
                        <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.order-confirmed-message-in-page-1")}}/>
                        {!userData &&
                            <>
                                <a href={t("ecommerce.checkout.continue-shopping-link")}><b>{t("ecommerce.checkout.continue-shopping-label")}</b></a>
                            </>
                        }
                        {userData &&
                            <>
                                <p>{t("ecommerce.checkout.temporary-order-confirmed-message-in-page-1")}</p>
                                <a href={t("ecommerce.checkout.go-to-my-orders-link")}>
                                    <b>{t("ecommerce.checkout.go-to-my-orders-label")}</b>
                                </a>
                            </>
                        }
                    </div>
                    {!userData && (
                        <div className="col-md-6 col-sm-12 column-thankyou">
                            <div>
                                <h3>{t("ecommerce.checkout.create-account-title")}</h3>
                                <p dangerouslySetInnerHTML={{__html: t("ecommerce.checkout.create-account-description")}}/>

                                {benefit1 && benefit1 !== "" &&
                                    <div className="d-inline-flex align-items-center w-100">
                                      <span className="checkout-point">
                                        <svg className="centered-thick-check" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.7574 0.32313C10.9775 0.113581 11.2704 -0.00226582 11.5743 3.35865e-05C11.8782 0.00233299 12.1693 0.122599 12.3862 0.335454C12.6031 0.548309 12.7288 0.837105 12.7369 1.1409C12.7449 1.4447 12.6346 1.73973 12.4292 1.96375L6.19486 9.76063C6.08766 9.8761 5.95827 9.96876 5.81444 10.0331C5.67061 10.0974 5.51528 10.1321 5.35775 10.135C5.20022 10.1379 5.04372 10.109 4.8976 10.0501C4.75149 9.99111 4.61876 9.9033 4.50736 9.79188L0.372982 5.6575C0.257846 5.55022 0.165499 5.42084 0.101449 5.2771C0.0373992 5.13335 0.0029586 4.97817 0.000182375 4.82082C-0.00259385 4.66347 0.0263513 4.50718 0.0852904 4.36126C0.144229 4.21534 0.231955 4.08279 0.343235 3.97151C0.454514 3.86023 0.587067 3.7725 0.732986 3.71356C0.878905 3.65462 1.0352 3.62568 1.19255 3.62846C1.3499 3.63123 1.50507 3.66567 1.64882 3.72972C1.79257 3.79377 1.92195 3.88612 2.02923 4.00125L5.30111 7.27157L10.7277 0.357505C10.7374 0.345475 10.7479 0.333999 10.7589 0.32313H10.7574Z" fill="white"/>
                                        </svg>
                                      </span>
                                      <span className="pl-2">{benefit1}</span>
                                    </div>
                                }

                                {benefit2 && benefit2 !== "" &&
                                    <div className="d-inline-flex align-items-center w-100">
                                      <span className="checkout-point">
                                        <svg className="centered-thick-check" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.7574 0.32313C10.9775 0.113581 11.2704 -0.00226582 11.5743 3.35865e-05C11.8782 0.00233299 12.1693 0.122599 12.3862 0.335454C12.6031 0.548309 12.7288 0.837105 12.7369 1.1409C12.7449 1.4447 12.6346 1.73973 12.4292 1.96375L6.19486 9.76063C6.08766 9.8761 5.95827 9.96876 5.81444 10.0331C5.67061 10.0974 5.51528 10.1321 5.35775 10.135C5.20022 10.1379 5.04372 10.109 4.8976 10.0501C4.75149 9.99111 4.61876 9.9033 4.50736 9.79188L0.372982 5.6575C0.257846 5.55022 0.165499 5.42084 0.101449 5.2771C0.0373992 5.13335 0.0029586 4.97817 0.000182375 4.82082C-0.00259385 4.66347 0.0263513 4.50718 0.0852904 4.36126C0.144229 4.21534 0.231955 4.08279 0.343235 3.97151C0.454514 3.86023 0.587067 3.7725 0.732986 3.71356C0.878905 3.65462 1.0352 3.62568 1.19255 3.62846C1.3499 3.63123 1.50507 3.66567 1.64882 3.72972C1.79257 3.79377 1.92195 3.88612 2.02923 4.00125L5.30111 7.27157L10.7277 0.357505C10.7374 0.345475 10.7479 0.333999 10.7589 0.32313H10.7574Z" fill="white"/>
                                        </svg>
                                      </span>
                                      <span className="pl-2">{benefit2}</span>
                                    </div>
                                }

                                {benefit3 && benefit3 !== "" &&
                                    <div className="d-inline-flex align-items-center w-100">
                                      <span className="checkout-point">
                                        <svg className="centered-thick-check" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.7574 0.32313C10.9775 0.113581 11.2704 -0.00226582 11.5743 3.35865e-05C11.8782 0.00233299 12.1693 0.122599 12.3862 0.335454C12.6031 0.548309 12.7288 0.837105 12.7369 1.1409C12.7449 1.4447 12.6346 1.73973 12.4292 1.96375L6.19486 9.76063C6.08766 9.8761 5.95827 9.96876 5.81444 10.0331C5.67061 10.0974 5.51528 10.1321 5.35775 10.135C5.20022 10.1379 5.04372 10.109 4.8976 10.0501C4.75149 9.99111 4.61876 9.9033 4.50736 9.79188L0.372982 5.6575C0.257846 5.55022 0.165499 5.42084 0.101449 5.2771C0.0373992 5.13335 0.0029586 4.97817 0.000182375 4.82082C-0.00259385 4.66347 0.0263513 4.50718 0.0852904 4.36126C0.144229 4.21534 0.231955 4.08279 0.343235 3.97151C0.454514 3.86023 0.587067 3.7725 0.732986 3.71356C0.878905 3.65462 1.0352 3.62568 1.19255 3.62846C1.3499 3.63123 1.50507 3.66567 1.64882 3.72972C1.79257 3.79377 1.92195 3.88612 2.02923 4.00125L5.30111 7.27157L10.7277 0.357505C10.7374 0.345475 10.7479 0.333999 10.7589 0.32313H10.7574Z" fill="white"/>
                                        </svg>
                                      </span>
                                      <span className="pl-2">{benefit3}</span>
                                    </div>
                                }

                                {benefit4 ? (
                                    <span>{benefit4}</span>
                                ) : null}
                                <p dangerouslySetInnerHTML={{__html: createAccountMessage}}/>
                                <button className="btn-checkout" onClick={handleRedirect}>
                                    {t("ecommerce.checkout.create-account-title")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledPages>
    )
}
