import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { StyledPages } from "./commonPages.style";
import { GeneralContext } from "../core/general.context";
import { Button } from "../components/button/button.component";
import { Box } from "../components/box/box.component";
import { UserContext } from "../core/user.context";

export const ActivatePage = (props: any) => {
    const container = document.getElementById("activate");
    const { userData } = React.useContext(GeneralContext);
    const { verifyCostumerEmail } = React.useContext(UserContext);
    const { t } = React.useContext(LabelContext);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');


    const openLogin = () => {

        let btnLoginLayer: any = document.getElementsByClassName("ec_btn-accountLayer")[0];
        btnLoginLayer.click();
    }

    React.useEffect(
        () => {
            if(userData && !userData.isEmailVerified && token){
                verifyCostumerEmail(token)
            }
        }, 
    [userData])

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle title={t("ecommerce.pages.activate-title")}></HeaderTitle>
                    <div className="container">
                        <Box className="secondary">

                            <div className="row justify-content-center">
                                <div className="col-md-4 text-center">
                                    {!userData &&
                                        <>
                                            <p>{t("ecommerce.pages.login-for-activate-mail-message")}</p>
                                            <Button className="primary" onClick={openLogin} label={t("ecommerce.login.signIn")}></Button>
                                        </>
                                    }
                                    {userData && userData.isEmailVerified && <p>{t("ecommerce.pages.mail-already-active-message")}</p>}
                                    {/* userData && !userData.isEmailVerified && <Button label={t("ecommerce.login.activateMail")} className="primary" onClick={() => { handleActivate() }}></Button> */}

                                    {userData && !userData.isEmailVerified && <p>{t("ecommerce.pages.mail-activation-error")}</p> }
                                </div>
                            </div>
                        </Box>
                    </div>

                </StyledPages>

            </>
            , container
        )
};