import React from "react";
import { calls, ENVIRONMENT, LABELS } from "./APIUtils";
import {AWSlogger, RESTContext} from "./rest.context";
import { ILabelContext } from "../common/common.models";

export const LabelContext = React.createContext<ILabelContext>(null!);

export const LabelContextComponent = (props: any) => {
    const { ajax$ } = React.useContext(RESTContext);
    const [labels, setLabels] = React.useState<any>([]);

    /* === FUNZIONI === */

    const init = () => {
        if(LABELS !== null && LABELS !== ""){
            setLabels(JSON.parse(LABELS))
        } else {
            ajax$(calls.Project.loadLabels(), undefined, undefined)
                .subscribe(res => {
                        setLabels(res);
                    },
                    error => {
                        AWSlogger({
                            type: "log",
                            action: "label.context",
                            message: "failed to retrieve labels",
                        })
                    });
        }
    };

    const t = (key: string) => {
        let result = "???" + key + "???";
        if (labels) {
            let labelFound = labels.find((e: { key: string; }) => {
                return e.key === key
            });
            if (labelFound && labelFound.value !== null && labelFound.value !== undefined) {
                result = labelFound.value;
            }else if(ENVIRONMENT==="production"){
                result = ""
            }
        }else if(ENVIRONMENT==="production"){
            result = ""
        }
        return result;
    }

    React.useEffect(() => {
        init();
    }, []);

    return (
        <LabelContext.Provider value={{
            t: t,
            labels
        }}>
            {props.children}
        </LabelContext.Provider>
    );
};