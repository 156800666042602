import styled from "styled-components";

export const StyledPages = styled.div`
color:var(--color-primary);
padding-bottom: 100px;

h1 { 
	font-size:2rem;

}

h2 { 
	font-size:30px;
	margin-bottom:30px;
}

h3 { 
	font-size:20px;
	margin-bottom:20px;
}


h4 { 
	font-size:16px;
	margin-bottom:16px;
	font-weight:bold;
}

@media (max-width: 768px) {
    min-height:auto;
	h1 { 
		margin:30px 0 10px 0;
		font-size:24px;

	}

	h2 { 
		font-size:22px;
		margin-bottom:20px;
	}

	h3 { 
		font-size:20px;
		margin-bottom:16px;
		margin-top:20px;
	}


	h4 { 
		font-size:16px;
		margin-bottom:10px;
		font-weight:bold;
	}

}

.container-bootstrap{
	max-width: 100%;
	padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
	.container-bootstrap{
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container-bootstrap{
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container-bootstrap{
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container-bootstrap{
		max-width: 1140px;
	}
}

.column-thankyou{
	background-color: #f4f4f4;
	padding: 1rem;

	.secondary{
		border-bottom: none;
	}

	.compact{
		margin: 0;
	}
}

.btn-checkout{
    padding: 5px 30px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
	min-width: 205px;
}

.btn-outline-checkout{
	background: white;
    border: 2px solid black;
    color: black;
    padding: 0px 30px;
    font-weight: 600;
	min-width: 300px;
}

.checkout-point{
	width: 25px;
	height: 25px;
	background: black;
	border-radius: 25px;
	display: block;
	position: relative;
}

.centered-thick-check{
	position: absolute;
    left: 6px;
    top: 8px;
}

.margin-breadcrumb{
	@media (min-width: 1200px) and (max-width: 1535px){
		margin-top: 1rem;
	}
}

`;