import styled from "styled-components";

export const StyledCartPage = styled.div`
{
    .row-cartTitle {
        align-items: flex-end;
        @media (max-width: 768px) {
            margin:30px 0 10px 0;
        }
        @media (min-width: 992px) {
            max-width: 66.666667%;
        }

        h1 {
            margin: 0;
            font-size: 2rem;
            font-weight: 700;
        }

        .product-count {
            font-weight: 700;
            font-size: 1rem;
            text-align: right;

            @media (min-width: 992px) {
                text-align: right;
                padding-right: 5%;
            }
        }
    }
    .cartProduct {
        .cartProduct-details {
            .focus {
                font-weight: 400;
                line-height: normal;
                font-size: 0.75rem;
            }
            .focus-info:not(.focus-info-small) {
                font-weight: 400;
                line-height: normal;
                font-size: 1rem;
            }
        }
    }

    .row-cart{
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }
    
    .accordion-button:not(.collapsed) {
        transform: rotate(0deg);
    }
    .accordion-button {
        display: inline-flex;
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 10px;
        margin-top: 7px;
        content: "";
        background-image: url(../webfiles/latest/images/icons/arrow-accordion.svg);
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
        transform: rotate(-180deg);
    }

}
`;