import styled from "styled-components";

export const StyledBackInStockForm = styled.div`

    .alert{
        display: flex;
        align-items: center;
        font-weight:bold;
        padding:unset;
        line-height:initial;
        &.alert--success{
            color:var(--color-success);
        }
        &.alert--error{
            color:var(--color-alert);            
        }
    }
`;

export const StyledBackInStockFormInPage = styled.div`
 {
    background: #F4F4F4;
    padding: 25px;
    margin: 0 15px;
    .message--alert {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
    }
    .message {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .noFloatLabel {
        input + label {
            order: 1;
            transform: translate(0);
            position: static;
        }
        input {
            order: 2;
            border: 1px solid #B6B6B6;
            height: 2.8rem;
        }
    }
    .primary.actionForm {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 16px;
    }
    .input-wrap {
        align-items: flex-end;
        width: 100%;

        @media only screen and (max-width: 600px)  {
            margin-right: 0;
            margin-left: 0;
        }

        .col {
            flex-basis: auto;
        }

        .input-mail {
            width: 60%;

            @media only screen and (max-width: 600px)  {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .input-btn {
            width: 40%;
            @media only screen and (max-width: 600px)  {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                button {
                    width: 100%;
                }
            }
        }
        .checkboxButton {
            width: 100%;
            @media only screen and (max-width: 600px)  {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .input-mail{
        padding-right: 0;
        @media only screen and (max-width: 767px)  {
            padding-right: 15px;
        }
    }

    .alert{
        display: flex;
        align-items: center;
        font-weight:bold;
        padding:unset;
        line-height:initial;
        &.alert--success{
            color:var(--color-success);
        }
        &.alert--error{
            color:var(--color-alert);            
        }
    }
 }

    
`;