import styled from "styled-components"

export const StyledCheckout = styled.div`
  .processingOrderStyle {
        opacity: 0.05;
  }
    .processingOrderMessage {
      text-align: center;
      font-weight: bold;
  }
    .loader,
    .loader:before,
    .loader:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: load7 1.8s infinite ease-in-out;
      animation: load7 1.8s infinite ease-in-out;
    }
    .loader {
      color: #000000;
      font-size: 10px;
      margin: 80px auto;
      position: relative;
      text-indent: -9999em;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
      content: '';
      position: absolute;
      top: 0;
    }
    .loader:before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .loader:after {
      left: 3.5em;
    }
    @-webkit-keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
    @keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }

.form-control-bootstrap{
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.checkout-form {
  padding-bottom: 42px;
  border-bottom: 1px solid #B6B6B6;
}

.checkout-form label{
  font-weight: bold;
}

.checkout-radio{
  width: 1.2rem;
  height: 1.2rem;
}

.radio-item {
  display: inline-block;
  position: relative;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item {
  display: inline-block;
  position: relative;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-weight: normal;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  @media (max-width: 768px){
    display: block;
    line-height: 24px;
  }
}

.radio-item label:before {
  content: " ";
  display: inline-flex;
  position: relative;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: transparent;
  margin-right: 5px;
}

.radio-item input[type=radio]:checked + label:before {
  border: 1px solid black;
}

.radio-item input[type=radio]:checked + label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: black;
  transform: translate(-50%, -50%);
  @media (max-width: 768px){
    top: 4px;
    left: 4px;
    transform: none;
  }
}

@media (min-width: 1200px) and (max-width: 1535.9px) {
  .radio-item input[type=radio]:checked + label:after {
    width: 13.5px;
    height: 13.5px;
  }
}

@media (min-width: 1536px) and (max-width: 1919.9px) {
  .radio-item input[type=radio]:checked + label:after {
    width: 13.5px;
    height: 13.5px;
  }
}

input[type=radio] {
  vertical-align: text-top;
}


.checkout-column-aside{
  @media (max-width: 992px) {
    padding-bottom: 1rem;
  }
}

textarea{
  border-color: #B3B3B3;
}

.btn-payment-methods{
  // line-height: 1.5;
  width: 100%;
  color: black !important;
  text-transform: capitalize;
  text-align: left;
  cursor: pointer;
  font-weight: 300;
}

.container-payment-methods{
  border: 1px solid #B3B3B3;
  padding: 15px 20px;
}

.row-checkout{
  @media (max-width: 992px){
    flex-direction: column-reverse;
  }
}

.payment-bar{
  .priceRecap-total{
    border: 0;
  }
}

.priceRecap-total{
  border-top: 1px solid #B6B6B6;
  border-bottom: 1px solid #B6B6B6;
  padding: 10px 0;
}

.product-information{
  line-height: 18px;
  @media (min-width: 1200px) and (max-width: 1367px) {
    font-size: 10px;
  }
}

.product-quantity{
  @media (min-width: 1200px) and (max-width: 1367px) {
    font-size: 10px;
  }
}

.font-size-14{
  font-size: 14px;
}

.btn-radio-item{
  width: 100%;
  background: white;
  color: black;
  border: 1px solid #B3B3B3;
  text-transform: inherit;
  vertical-align: middle;
  text-align: left;
  padding: 30px 20px;
  max-width: none;
  margin: 0;
  font-family: Mulish, sans-serif;
  font-weight: 300;
}

.btn-icon-younitedPay{
  width: 100px;
}

.accordion-button:not(.collapsed) {
  transform: rotate(0deg);
}
.accordion-button {
  display: inline-flex;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 10px;
  margin-top: 7px;
  content: "";
  background-image: url(../webfiles/latest/images/icons/arrow-accordion.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  transform: rotate(-180deg);
}

.email-information{
  font-size: 14px;
  line-height: 24px;
  @media (min-width: 1200px) and (max-width: 1367px) {
    font-size: 10px;
  }
}

label{
  .payment-icon{
    width: 4rem;
  }
}

.price {
  @media (max-width: 768px){
    line-height: 26px;
  }
}

h3 {
  margin-bottom: 0;
}

`
