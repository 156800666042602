import * as React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { GeneralContext } from "../core/general.context";
import { StyledPages } from "./commonPages.style";
import { CartContext } from "../core/cart.context";
import { calls } from "../core/APIUtils";
import { RESTContext } from "../core/rest.context";
import { getAnalyticsProducts, parsePrice } from "../common/constants";

export const KlarnaConfirmationPage = (_props: any) => {
    const [orderId, setOrderId] = useState<string>();
    const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
    const [klarnaOrder, setKlarnaOrder] = useState<any>();
    const container = document.getElementById("reactPlaceholder_klarna-confirmation");
    const { t } = React.useContext(LabelContext);
    const { userData, klarna } = React.useContext(GeneralContext);
    const { getKlarnaCreate } = React.useContext(CartContext);
    const { ajax$ } = React.useContext(RESTContext);
    const localStorage = (window as any).localStorage || []
    const dataLayer = (window as any).dataLayer || []
    const analytics: any = localStorage?.analytics ? JSON.parse(localStorage?.analytics) : {}

    useEffect(() => {
        let params: URLSearchParams = new URLSearchParams(window.location.search)
        if (params?.get("sid")) {
            const sid: string = params?.get("sid") || ""
            setOrderId(sid);
        }
    }, [])

    useEffect(() => {
        if (orderId) {
            getKlarnaCreate(orderId)
        }
    }, [orderId])

    useEffect(() => {
        if (klarna) {
            const script = document.querySelectorAll("#klarna-container script")[0]?.innerHTML;
            // eslint-disable-next-line no-eval
            script && window.eval(script);
            setIsApiLoading(!isApiLoading);
            if (klarna?.obj?.ctOrder) {
                ajax$(calls.Project.getOrder(klarna?.obj?.ctOrder))
                    .subscribe(
                        res => {
                            setKlarnaOrder(res);
                        }
                    );
            } else {
                if (klarna?.obj?.cartId) {
                    ajax$(calls.Project.getMyCartById(klarna?.obj?.cartId))
                        .subscribe(
                            res => {
                                setKlarnaOrder(res);
                            }
                        );
                }
            }
        }
    }, [klarna])

    useEffect(() => {
        if (klarnaOrder) {
            dataLayer.push({
                pageLang: `${analytics?.currentLang || ""}`,
                pageType: `${klarnaOrder ? "purchase" : analytics?.pageType}`,
                visitorId: `${userData?.id}`,
                visitorLoginState: `${userData?.id ? "logged-in" : "not-logged"}`,
                visitorNewsletterSubscription: `${userData?.custom?.fields?.subscribed ? "yes" : "no"}`,
            })

            dataLayer.push({
                event: "checkoutOption",
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 2,
                            option: [
                                `${klarnaOrder?.shippingInfo?.shippingMethodName} - ${klarnaOrder?.paymentInfo?.payments[0]?.obj?.paymentMethodInfo?.paymentInterface}`,
                            ],
                        },
                    },
                },
            })

            const { lineItems } = klarnaOrder

            dataLayer.push({
                pageType: "purchase",
                visitorId: `${userData?.id}`,
            })
            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currencyCode: `${klarnaOrder?.totalPrice?.currencyCode}`,
                    purchase: {
                        actionField: {
                            id: `${klarnaOrder?.orderNumber}`,
                            affiliation: 'Online store',
                            revenue: `${parsePrice(klarnaOrder?.totalPrice, true)}`,
                            tax: `${parsePrice(klarnaOrder?.taxedPrice?.taxPortions[0]?.amount, true)}`,
                            shipping: `${klarnaOrder?.shippingInfo?.price?.centAmount}`,
                            coupon: `${klarnaOrder?.discountCodes?.map((item: any) => { return item?.discountCode?.obj?.code }).toString()}`,
                        },
                        products: getAnalyticsProducts(lineItems),
                    }
                }
            });
        } else {
            dataLayer.push({
                'pageLang': `${analytics?.currentLang}`,
                'pageType': `${analytics?.pageType ? analytics?.pageType : (analytics?.sitemap === 'root') ? 'homepage' : 'other'}`,
                'visitorId': `${userData?.id}`,
                'visitorLoginState': `${userData?.id ? 'logged-in' : 'not-logged'}`,
                'visitorNewsletterSubscription': `${userData?.custom?.fields?.subscribed ? 'yes' : 'no'}`,
            });
        }
    }, [klarnaOrder])

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(

            <StyledPages>
                <HeaderTitle
                    title={t("ecommerce.pages.thankYou-title")}
                    message={t("ecommerce.pages.thankYou-message")} />

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div id="klarna-container" className="isLoading" dangerouslySetInnerHTML={{ __html: klarna?.obj?.html_snippet }} />
                        </div>
                    </div>
                </div>
            </StyledPages>
            , container
        )
};
