import styled from "styled-components";


export const LoaderComponent = (props: any) => {
  // return <StyledLoader className='ecommerce_loader'>
  //    <Loader
  //       type="Circles"
  //       color="#fff"
  //       height={100}
  //       width={100}
  //       // timeout={3000} //3 secs
  //     />
  // </StyledLoader>
  return <div className="isLoading" />
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLoader = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
`;