import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { StyledPages } from "./commonPages.style";
import { Box } from "../components/box/box.component";
import { InputElement } from "../components/input/input.component";
import { Button } from "../components/button/button.component";
import { LabelContext } from "../core/label.context";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserContext } from "../core/user.context";

type forgotPasswordValue = {
  email: string;
};

export const ForgotPasswordPage = (props: any) => {
  const container = document.getElementById("forgotPassword");
  const { forgotPassword, forgotPasswordSent } = React.useContext(UserContext);
  const { t } = React.useContext(LabelContext);

  let requiredError = t("ecommerce.form-messagge.required");

  const schema = yup.object().shape({
    email: yup.string().email().required(requiredError),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<forgotPasswordValue>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<forgotPasswordValue> = (data) => {
    forgotPassword(data.email);
  };

  if (!container) return null;
  else
    return ReactDOM.createPortal(
      <>
        <StyledPages>
          <HeaderTitle
            title={t("ecommerce.forgotPassword.title")}
          ></HeaderTitle>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <Box className="unbordered secondary">
                  {!forgotPasswordSent && (
                    <div className="centerBlocks">
                      <div className="text-center">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <InputElement
                            label={t("ecommerce.login.email")}
                            id="emailForgotPassword"
                            externalRegister={register("email")}
                            errors={errors.email?.message}
                          ></InputElement>
                          <Button
                            className="primary mt-4"
                            label={t("ecommerce.common.send")}
                            type="submit"
                          />
                        </form>
                      </div>
                    </div>
                  )}
                  {forgotPasswordSent && (
                    <>
                      <div className="text-center">
                        <h2>{t("ecommerce.forgotPassword.sent-mail-title")}</h2>
                        <p>
                          {t("ecommerce.forgotPassword.sent-mail-messagge")}
                        </p>
                      </div>
                    </>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </StyledPages>
      </>,
      container
    );
};
