/**
 * CUSTOMER CARE INFO telephone
 *
 *
 * used in cart and account
 *
 *
 */

import * as React from "react";
import { LabelContext } from "../../core/label.context";
import styled from "styled-components";

export const CustomercareInfoTelephone = () => {
  const { t } = React.useContext(LabelContext);

  let ecommerce_customerCare_info_telephone = t(
    "ecommerce.customerCare.info.telephone"
  );

  if (!ecommerce_customerCare_info_telephone) return null;
  else {
    return (
      <>
        <TelBox className="customerCareInfo-box-tel aaa">
          <span className="icon"/>
          <div
            className="testo"
            dangerouslySetInnerHTML={{
              __html: ecommerce_customerCare_info_telephone,
            }}
          />
        </TelBox>
      </>
    );
  }
};

export const TelBox = styled.div`
  .icon {
    background-position: center!important;
  }
`;
