import * as React from "react";
import ReactDOM from "react-dom";
import { HeaderTitle } from "../components/header/title/headerTitle.component";
import { LabelContext } from "../core/label.context";
import { GeneralContext } from "../core/general.context";
import { StyledPages } from "./commonPages.style";
import { parsePrice } from "../common/constants";
import { Order } from "../components/orders/order.component";

export const ThankYouPage = (props: any) => {
    const container = document.getElementById("thankYou");
    const { t } = React.useContext(LabelContext);
    const { orders } = React.useContext(GeneralContext);


    let params = new URLSearchParams(window.location.search)
    let orderNumber = params.get("order");
    let lastOrder = orders.results?.find((o: any) => o.orderNumber === orderNumber)

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <>
                <StyledPages>
                    <HeaderTitle
                        title={t("ecommerce.pages.thankYou-title")}
                        message={t("ecommerce.pages.thankYou-message")}></HeaderTitle>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div>Codice ordine: {lastOrder.orderNumber}</div>
                                <div>Totale ordine: {parsePrice(lastOrder.totalPrice)}</div>
                                <Order hideButton={true} order={lastOrder} />
                            </div>
                        </div>
                    </div>
                </StyledPages>

            </>
            , container
        )
};

