import React, {useRef} from 'react';
import ReactDOM from "react-dom";
import {LabelContext} from "../../core/label.context";
import {SymbolContext} from "../../core/symbol.context";
import {
    BACK_IN_STOCK_LISTING_ENABLED,
    BRX_BUNDLE_URL,
    BRX_LOCALE,
    BRX_PRODUCTS_URL,
    calls,
    PRODUCT_WITH_LIMITED_QTA_ENABLED,
    PROJECT_COUNTRY,
    PROJECT_KEY,
    PROJECT_LANGUAGE,
    PROJECT_SETTINGS, SHOW_ENERGY_LABEL
} from "../../core/APIUtils";
import {RESTContext} from "../../core/rest.context";
import styled from "styled-components"
import {CartContext} from "../../core/cart.context"
import {GeneralContext} from "../../core/general.context"
import {
    getBundlePrice, getContentVisibility,
    getCurrentPrice,
    getEnergyClassLabel,
    removeBundleCancelledFromProductProjection
} from "../bundle/utils"
import {nordics, openCartLayer, parsePrice} from "../../common/constants"

const StyledGrid = styled.div`
          
        `;

const StyledFiltersContainerWrap = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;

    button.scroll-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        flex-grow: 1;
        font-size: 16px;
        padding: 0px 13px;
        border: 1px solid black;
        cursor: pointer;
        border-radius: 30px;
        font-family: 'Raleway';
        background-color: #ffffff;
        white-space: nowrap;
        top: 3px;
        cursor: pointer;
        color: #000;
        margin-top: 3px;

        &:visited,
        &:active,
        &:hover {
            color: #000;
        }

        &.left-button {
            margin-left: -30px;
            margin-right: 5px;
        }
        &.right-button {
            margin-right: -30px;
            margin-left: 5px;
        }
    }
`;

const StyledFiltersContainer = styled.div`
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      justify-content: center;
      overflow-x: auto;
      padding-bottom: 10px;
      max-width: fit-content;
      justify-content: flex-start;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;
      
      &.colorContainer {
        overflow: visible;
        max-width: auto;
        flex-wrap: wrap;
      }

      &::-webkit-scrollbar {
        width: 1px; 
        height: 1px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #fff; /* Colore nero per il thumb (il "pulsante" della scrollbar) */
    }
    `;

const StyledButton = styled.button`
        font-size: 16px;
        padding: 4px 20px;
        border: 1px solid black;
        cursor: pointer;
        border-radius: 30px;
        font-family: 'Raleway';
        background-color: #ffffff;
        white-space: nowrap;
        color: #000;

        &:active,
        &:visited,
        &:hover {
            color:#000;
        }
            
        &.selected {
            border: 1px solid #E3D9C6;
            background-color: #E3D9C6;
        }
        
        &.disabled {
            color: #AAA;
            border: 1px solid #AAA;
            cursor: not-allowed;
        }

        @media (min-width: 1200px) and (max-width: 1535.9px) {
            font-size: 10px;
            padding: 0 15px;
        }
    `;

const StyledParagraph = styled.p`
        text-align: center;
        color: #000;
        line-height: normal;

        &:empty{
            margin: 0;
            line-height: 0;
            padding: 0;
        }

`;

const StyledParagraphFirst = styled.p`
        text-align: center;
        color: #000;
        line-height: normal;
        margin-top: 20px;

        &:empty{
            margin: 0;
            line-height: 0;
            padding: 0;
        }

`;

//interfaccia per definire le props da passare allo StyledColorButton. (passo dinamicamente il colore e l'hover text)
interface ColorButtonProps {
    bgColor?: string;
    bgImg?: string;
}

const StyledColorButton = styled.button<ColorButtonProps>`
    font-size: 16px;
    padding: 10px;
    border: 1px solid #d8d7d7;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    background-color: ${props => props.bgColor || 'transparent'};
    background-image: url(${props => props.bgImg});
    background-size: cover;
    background-repeat: no-repeat;
    
    &.selected {
        font-weight: bold;
        text-decoration: underline;

        :after {
            content: "";
            text-align: center;
            display: block;
            width: 34px;
            height: 34px;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            bottom: -3px;
            left: -3px;
        }
    }
    
    &.disabled {
        color: #ccc;
        cursor: not-allowed;  
        
        &:before {
            height: 100%;
            width: 100%;
            background-color: hsla(0,0%,100%,.5);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
        }
        &:after {
            display: block;
            position: absolute;
            content: "x";
            top: -4px;
            left: 35%;
            color: #000;
            font-weight: 300;
            font-size: 16px;
        }
    }
`;

const StyledCheckboxButton = styled.label`
  font-size: 16px;
  cursor: pointer;
  user-select: none; 
  display: flex;
  align-items: center; 

  align-items: center;
    border-radius: 100px;
    display: flex;
    font-weight: 700;
    margin-bottom: 16px;

  input[type="checkbox"] {
    margin-right: 8px; 
  }
  .toggle__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.toggle-track {
    background: #fff;
    border: 1px solid #000;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: 30px;
    margin-right: 5px;
    position: relative;
    width: 60px;
}

.toggle-indicator {
    align-items: center;
    background: #000;
    border-radius: 24px;
    bottom: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 2px;
    outline: solid 2px transparent;
    position: absolute;
    transition: 0.25s;
    width: 24px;
}
.checkMark {
    fill: #fff;
    height: 20px;
    width: 20px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}
.toggle__input:checked + .toggle-track .toggle-indicator .checkMark {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    svg {
        vertical-align: super;
    }
}
.toggle__input:checked + .toggle-track .toggle-indicator {
    background: #000;
    transform: translateX(30px);
}
  
  span {
    color: black; 
  }

  input[type="checkbox"]:disabled + .toggle-track {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const Facets = (props: any) => {
    const {ajax$} = React.useContext(RESTContext);
    const { t } = React.useContext(LabelContext);
    const [products, setProducts] = React.useState<any>([])
    const [categories, setCategories] = React.useState<any>([])
    const [initialFilter, setInitialFilter] = React.useState<string>()
    const [initialCategories, setInitialCategories] = React.useState<any>([])
    const [initialFamilies, setInitialFamilies] = React.useState<any>([])
    const [initialColors, setInitialColors] = React.useState<any>([])
    const [initialProductAvailabilities, setInitialProductAvailabilities] = React.useState<any>([])
    const [availableProductAvailabilities, setAvailableProductAvailabilities] = React.useState<any>([])
    const [selectedProductAvailabilities, setSelectedProductAvailabilities] = React.useState<any>([])
    const [selectedCategories, setSelectedCategories] = React.useState<any>([])
    const [selectedFamilies, setSelectedFamilies] = React.useState<any>([])
    const [selectedColors, setSelectedColors] = React.useState<any>([])
    const [availableCategories, setAvailableCategories] = React.useState<any>([])
    const [availableFamilies, setAvailableFamilies] = React.useState<any>([])
    const [availableColors, setAvailableColors] = React.useState<any>([])
    const [expandedFilter, setExpandedFilter] = React.useState<boolean>(false)
    const [viewAll, setViewAll] = React.useState<boolean>(true)
    const [newColorMapLabels, setNewColorMapLabels] = React.useState<Record<string, string>>({});
    const [skuList, setSkuList] = React.useState<string[]>([])
    const [esInfo, setEsInfo] = React.useState<any>({})


    const resetFilters = () => {
        setSelectedCategories([]);
        setSelectedFamilies([]);
        setSelectedColors([]);
        setSelectedProductAvailabilities([]);
        setViewAll(true)
    }

    const fixedQuery = `
      limit=500&
      expand=productType&
      expand=masterVariant.attributes[*].value[*]&
      facet=variants.attributes.FAM_LIST_LOCALIZED.label.${PROJECT_LANGUAGE}&
      facet=variants.attributes.NEW_COLOR_LIST_LOCALIZED.key&
      facet=categories.id&
      facet=variants.availability.isOnStock&
      sort=variants.availability.availableQuantity%20desc
    `.replace(/\s+/g, '');
    const container = document.getElementById("facets");

    const getInitialCategoriesFilterQuery = (categoryUUIDs: string[]) => {
        return "&filter.query=categories.id:\"" + categoryUUIDs.join("\",\"") + "\"";
    }

    const getCategoriesFilterQuery = (categoryUUIDs: string[]) => {
        let result = "";
        if(categoryUUIDs && categoryUUIDs.length > 0) {
            categoryUUIDs.forEach((categoryUUID: string) => {result += '"' + categoryUUID + '",'})
            result = result.slice(0, -1)
            result = "&filter=categories.id:" + result + "&filter.facets=categories.id:" + result
        }
        return result
    }

    const getFamiliesFilterQuery = (familyTitles: string[]) => {
        let result = "";
        if(familyTitles && familyTitles.length > 0){
            familyTitles.forEach((familyTitle: string) => {result += '"' + familyTitle + '",'})
            result = result.slice(0, -1)
            result = `&filter=variants.attributes.FAM_LIST_LOCALIZED.label.${PROJECT_LANGUAGE}:${result}&filter.facets=variants.attributes.FAM_LIST_LOCALIZED.label.${PROJECT_LANGUAGE}:${result}`
        }
        return result
    }

    const getColorsFilterQuery = (colorTitles: string[]) => {
        let result = "";
        if(colorTitles && colorTitles.length > 0){
            colorTitles.forEach((colorTitle: string) => {result += '"' + colorTitle + '",'})
            result = result.slice(0, -1)
            result = "&filter=variants.attributes.NEW_COLOR_LIST_LOCALIZED.key:" + result + "&filter.facets=variants.attributes.NEW_COLOR_LIST_LOCALIZED.key:" + result
        }
        return result
    }

    const getProductAvailabilitiesFilterQuery = (productAvailabilities: string[]) => {
        let result = "";
        if(productAvailabilities && productAvailabilities.length > 0){
            productAvailabilities.forEach((productAvailability: string) => {result += '"' + productAvailability + '",'})
            result = result.slice(0, -1)
            result = "&filter=variants.availability.isOnStock:" + result + "&filter.facets=variants.availability.isOnStock:" + result
        }
        return result
    }

    const manageSelectedCategories = (selectedCategory: string) => {
        let newSelectedCategories = JSON.parse(JSON.stringify(selectedCategories))
        if(newSelectedCategories.includes(selectedCategory)){
            newSelectedCategories = newSelectedCategories.filter((cat: string) => cat !== selectedCategory);
        }else{
            newSelectedCategories.push(selectedCategory)
        }
        setSelectedCategories(newSelectedCategories)
        setViewAll(false)
    }

    const manageSelectedFamilies = (selectedFamily: string) => {
        let newSelectedFamilies = JSON.parse(JSON.stringify(selectedFamilies))
        if(newSelectedFamilies.includes(selectedFamily)){
            newSelectedFamilies = newSelectedFamilies.filter((fam: string) => fam !== selectedFamily);
        }else{
            newSelectedFamilies.push(selectedFamily)
        }
        setSelectedFamilies(newSelectedFamilies)
        setViewAll(false)
    }

    const manageSelectedColors = (selectedColor: string) => {
        let newSelectedColors = JSON.parse(JSON.stringify(selectedColors))
        if(newSelectedColors.includes(selectedColor)){
            newSelectedColors = newSelectedColors.filter((color: string) => color !== selectedColor);
        }else{
            newSelectedColors.push(selectedColor)
        }
        setSelectedColors(newSelectedColors)
        setViewAll(false)
    }

    const manageSelectedProductsAvailabilities = (selectedAvailability: string) => {
        let newSelectedAvailabilities = JSON.parse(JSON.stringify(selectedProductAvailabilities))
        if(newSelectedAvailabilities.includes(selectedAvailability)){
            newSelectedAvailabilities = newSelectedAvailabilities.filter((productAvailability: string) => productAvailability !== selectedAvailability);
        }else{
            newSelectedAvailabilities.push(selectedAvailability)
        }
        setSelectedProductAvailabilities(newSelectedAvailabilities)
        setViewAll(false)
    }

    const getCategoriesWhereCondition = (categoryKeys: string[]) => {
        let categoryKeysWithValue: string[] = []
        categoryKeys.forEach((categoryKey: string) => {
            categoryKeysWithValue.push("key=\"" + categoryKey + "\"")
        })
        return encodeURIComponent(categoryKeysWithValue.join(" or "))
    }

    const getCategoryLabelByID = (categoryID: string) => {
        const found = categories.find((category: any) => {
            return category.id === categoryID;
        });
        return found.label
    }

    const containerRef1 = useRef<HTMLDivElement | null>(null);
    const containerRef2 = useRef<HTMLDivElement | null>(null);
    const [showLeftArrow1, setShowLeftArrow1] = React.useState(false);
    const [showRightArrow1, setShowRightArrow1] = React.useState(false);
    const [showLeftArrow2, setShowLeftArrow2] = React.useState(false);
    const [showRightArrow2, setShowRightArrow2] = React.useState(false);
  
    // Funzione per scorrimento fluido a sinistra
    const scrollLeft = (container:any) => {
      const scrollAmount = 100; // Modifica la quantità di scorrimento
      container.scrollTo({
        left: container.scrollLeft - scrollAmount,
        behavior: 'smooth',
      });
    };
  
    // Funzione per scorrimento fluido a destra
    const scrollRight = (container:any) => {
      const scrollAmount = 100; // Modifica la quantità di scorrimento
      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
    };
  
    // Funzione per controllare se il container è più largo della finestra
    const checkContainerWidth = (containerRef:any, setShowLeftArrow:any, setShowRightArrow:any) => {
      if (containerRef.current) {
        const scrollableContainer = containerRef.current;
        const isScrollable = scrollableContainer.scrollWidth > scrollableContainer.clientWidth;
        const isAtScrollStart = scrollableContainer.scrollLeft === 0;
        const isAtScrollEnd = scrollableContainer.scrollLeft === (scrollableContainer.scrollWidth - scrollableContainer.clientWidth);

        setShowLeftArrow(isScrollable && !isAtScrollStart);
        setShowRightArrow(isScrollable && !isAtScrollEnd);
      }
    };
  
    React.useEffect(() => {
      // Aggiungi un gestore di eventi di scrolling per mostrare/nascondere le frecce
      if (containerRef1.current) {
        checkContainerWidth(containerRef1, setShowLeftArrow1, setShowRightArrow1);
        containerRef1.current.addEventListener('scroll', () => {
          checkContainerWidth(containerRef1, setShowLeftArrow1, setShowRightArrow1);
        });
      }
  
      if (containerRef2.current) {
        checkContainerWidth(containerRef2, setShowLeftArrow2, setShowRightArrow2);
        containerRef2.current.addEventListener('scroll', () => {
          checkContainerWidth(containerRef2, setShowLeftArrow2, setShowRightArrow2);
        });
      }
  
      // Aggancia un gestore di eventi resize alla finestra
      window.addEventListener('resize', () => {
        checkContainerWidth(containerRef1, setShowLeftArrow1, setShowRightArrow1);
        checkContainerWidth(containerRef2, setShowLeftArrow2, setShowRightArrow2);
      });
  
      // Controlla inizialmente le dimensioni dei contenitori
      checkContainerWidth(containerRef1, setShowLeftArrow1, setShowRightArrow1);
      checkContainerWidth(containerRef2, setShowLeftArrow2, setShowRightArrow2);

      if (expandedFilter){
        checkContainerWidth(containerRef1, setShowLeftArrow1, setShowRightArrow1);
      }
    }, [containerRef1.current, containerRef2.current, expandedFilter]);


    React.useEffect(() => {
        let categoriesUUIDs: string[] = []
        if (container) {
            let categoriesData = container.getAttribute('data-category-keys')
            if (categoriesData) {

                let categoryMap: { id: any; key: any; label: any; }[] = []
                ajax$(calls.Project.queryCategories(getCategoriesWhereCondition(categoriesData.split(",")))).subscribe(_result => {
                    _result.results.forEach((category: any) => {
                        categoryMap.push({id: category.id, key: category.key, label: category.name[PROJECT_LANGUAGE]})
                    })
                    setCategories(categoryMap)
                    categoriesUUIDs = categoryMap.map((x: { id: any; }) => x.id)
                    let initialFilterQuery = getInitialCategoriesFilterQuery(categoriesUUIDs)
                    setInitialFilter(initialFilterQuery)

                    ajax$(calls.Project.getProductProjectionByQueryString(fixedQuery + initialFilterQuery)).subscribe(_products => {
                        setProducts(_products)

                        let familyTitles: string[] = []
                        _products.facets[`variants.attributes.FAM_LIST_LOCALIZED.label.${PROJECT_LANGUAGE}`].terms.forEach((family: any) => {
                            familyTitles.push(family.term)
                        })
                        familyTitles = familyTitles.sort()

                        let colorKeys: string[] = []
                        _products.facets["variants.attributes.NEW_COLOR_LIST_LOCALIZED.key"].terms.forEach((color: any) => {
                            colorKeys.push(color.term)
                        })

                        const tempColorMap: Record<string, string> = {};
                        const skus: string[] = []
                        _products.results.forEach((product: any) => {
                            const newColorAttribute = product.masterVariant.attributes.find((attribute: any) => attribute.name === 'NEW_COLOR_LIST_LOCALIZED');

                            if (newColorAttribute) {
                                //tempColorMap[newColorAttribute.value.key] = newColorAttribute.value.label;
                                newColorAttribute.value.map((newColor: any) => {
                                    tempColorMap[newColor.key] = newColor.label[PROJECT_LANGUAGE];
                                })
                            }

                            if(product.key) {
                                skus.push(product.key)
                            }
                        });
                        setSkuList(skus)

                        let productAvailebilities: string[] = []
                        _products.facets["variants.availability.isOnStock"].terms.forEach((availability: any) => {
                            //solo i prodotti disponibili
                            if(availability.term === 'T'){
                                productAvailebilities.push(availability.term)
                            }
                        })

                        setInitialCategories(categoriesUUIDs)
                        setAvailableCategories(categoriesUUIDs)

                        setInitialFamilies(familyTitles)
                        setAvailableFamilies(familyTitles)

                        setNewColorMapLabels(tempColorMap)
                        setInitialColors(colorKeys)
                        setAvailableColors(colorKeys)

                        setInitialProductAvailabilities(productAvailebilities)
                        setAvailableProductAvailabilities(productAvailebilities)
                    })
                })
            }
        }
    }, []);

    React.useEffect(() => {
        if (container && initialFilter){
            let filterCategoriesQuery = getCategoriesFilterQuery(selectedCategories)
            let filterFamiliesQuery = getFamiliesFilterQuery(selectedFamilies)
            let filterColorsQuery = getColorsFilterQuery(selectedColors)
            let filterProductAvailabilityQuery = getProductAvailabilitiesFilterQuery(selectedProductAvailabilities)

            ajax$(calls.Project.getProductProjectionByQueryString(fixedQuery + initialFilter + filterCategoriesQuery + filterFamiliesQuery + filterColorsQuery + filterProductAvailabilityQuery)).subscribe(
                _products => {
                    setProducts(_products)

                    let productAvailabilities: string[] = []
                    _products.facets["variants.availability.isOnStock"].terms.forEach((productAvailability: any) => {
                        productAvailabilities.push(productAvailability.term)
                    })
                    setAvailableProductAvailabilities(productAvailabilities)

                    let familyTitles: string[] = []
                    _products.facets[`variants.attributes.FAM_LIST_LOCALIZED.label.${PROJECT_LANGUAGE}`].terms.forEach((family: any) => {
                        familyTitles.push(family.term)
                    })
                    setAvailableFamilies(familyTitles)

                    let colorKeys: string[] = []
                    _products.facets["variants.attributes.NEW_COLOR_LIST_LOCALIZED.key"].terms.forEach((color: any) => {
                        colorKeys.push(color.term)
                    })
                    setAvailableColors(colorKeys)

                    let categoriesUUIDs: string[] = []
                    _products.facets["categories.id"].terms.forEach((categoriesUUID: any) => {
                        categoriesUUIDs.push(categoriesUUID.term)
                    })
                    setAvailableCategories(categoriesUUIDs)
                })
        }
    }, [selectedCategories, selectedFamilies, selectedColors, selectedProductAvailabilities]);

    React.useEffect(() => {
        if(skuList.length > 0){
            const body = {'sku-list': skuList}
            ajax$(calls.Project.getElasticSearchInfo(), null, body).subscribe(es_info => {
                setEsInfo(es_info)
            })
        }
    },[skuList]);

    //se c'e solo una categoria esplodo i filtri
    React.useEffect(() => {
        if(initialCategories.length === 1){
            setExpandedFilter(true)
        }
    },[initialCategories])

    if (!container || initialCategories.length <= 0 || initialFamilies.length <= 0 || initialColors.length <= 0)
        return null;

    return ReactDOM.createPortal(
        <div style={{marginBottom:'50px'}}>
            {/* <div>Total found: {products && products.total}</div> */}
            {initialCategories.length > 1 &&
                <>
                    <StyledParagraphFirst>{t("ecommerce.pages.facets-titleCategory")}</StyledParagraphFirst>
                    <StyledFiltersContainerWrap>
                        {showLeftArrow2 && (
                            <button className="scroll-button left-button" onClick={() => scrollLeft(containerRef2.current)}>{"<"}</button>
                        )}
                        <StyledFiltersContainer className="filterWrap filterWrap-categories" ref={containerRef2}>
                            <ButtonFilter filterState={viewAll ? 'selected' : 'selectable'} onClick={() => resetFilters()} filterLabel={t("ecommerce.pages.facets-viewAll")}/>
                                {
                                    initialCategories.map((category: any) => {
                                        let isAvailable = availableCategories.includes(category)
                                        let isSelected = selectedCategories.includes(category)
                                        let filterState: 'selected' | 'selectable' | 'disabled' = (isAvailable && isSelected) ? "selected" : (isAvailable && !isSelected) ? "selectable" : "disabled"
                                        return (<ButtonFilter filterState={filterState} onClick={() => manageSelectedCategories(category)} filterLabel={getCategoryLabelByID(category)}/>)
                                    })
                                }
                        </StyledFiltersContainer>
                        {showRightArrow2 && (
                            <button className="scroll-button right-button" onClick={() => scrollRight(containerRef2.current)}>{">"}</button>
                        )}
                    </StyledFiltersContainerWrap>

                    <div>
                        <StyledFiltersContainer>
                            <ButtonFilter style={{backgroundColor: 'black', color: 'white'}} filterState={'selectable'} onClick={() => setExpandedFilter(!expandedFilter)} filterLabel={expandedFilter ? t("ecommerce.pages.facets-hideFilters") : t("ecommerce.pages.facets-showFilters")}>
                                <span
                                    style={{
                                        // backgroundImage: 'url(https://www.smeg.com/webfiles/latest/images/icons/cart.svg)',
                                        // backgroundRepeat: "no-repeat",
                                        // width: "calc(100% - 16px)",
                                        // height: "100%",
                                        // backgroundPosition: "50%",
                                        // padding: "20px"
                                        marginLeft: '8px'
                                    }}
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-filter" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                </span>
                            </ButtonFilter>
                        </StyledFiltersContainer>
                    </div>
                </>
            }
            {expandedFilter &&
                <>
                <StyledParagraph>{t("ecommerce.pages.facets-titleFamily")}</StyledParagraph>
                <StyledFiltersContainerWrap>
                    {showLeftArrow1 && (
                        <button className="scroll-button left-button" onClick={() => scrollLeft(containerRef1.current)}>{"<"}</button>
                    )}

                        <StyledFiltersContainer className="filterWrap filterWrap-families" ref={containerRef1} style={{display: "block"}}>

                            {
                                initialFamilies.map((family: any) => {
                                    let isAvailable = availableFamilies.includes(family)
                                    let isSelected = selectedFamilies.includes(family)
                                    let filterState: 'selected' | 'selectable' | 'disabled' = (isAvailable && isSelected) ? "selected" : (isAvailable && !isSelected) ? "selectable" : "disabled"
                                    return (<ButtonFilter filterState={filterState} onClick={() => manageSelectedFamilies(family)} filterLabel={family}/>)
                                })
                            }

                        </StyledFiltersContainer>
                        {showRightArrow1 && (
                        <button className="scroll-button right-button" onClick={() => scrollRight(containerRef1.current)}>{">"}</button>
                        )}
                    </StyledFiltersContainerWrap>
                    <StyledParagraph>{t("ecommerce.pages.facets-titleColor")}</StyledParagraph>
                    <div>
                        <StyledFiltersContainer className="colorContainer">
                            {
                                initialColors.map((color: string) => {
                                    let isAvailable = availableColors.includes(color)
                                    let isSelected = selectedColors.includes(color)
                                    let filterState: 'selected' | 'selectable' | 'disabled' = (isAvailable && isSelected) ? "selected" : (isAvailable && !isSelected) ? "selectable" : "disabled"
                                    const colorHexLabel = t("NEW_COLOR_" + color)
                                    const specialColor = "https://www.smeg.com/binaries/content/gallery/smeg/color-filters/new_color_" + color + ".png"
                                    const filterLabel = colorHexLabel.includes('|') ? colorHexLabel.split('|')[1] : colorHexLabel
                                    const hoverText = newColorMapLabels[color] || ''
                                    return (<ColorFilter filterState={filterState} onClick={() => manageSelectedColors(color)} colorKey={color} filterLabel={filterLabel} hoverText={hoverText} colorName={specialColor}/>)
                                })
                            }
                        </StyledFiltersContainer>
                    </div>
                    
                </>
            }
            <div>
                <div>
                    {
                        initialProductAvailabilities.map((productAvailability: any) => {
                            let isAvailable = availableProductAvailabilities.includes(productAvailability)
                            let isSelected = selectedProductAvailabilities.includes(productAvailability)
                            let filterState: 'selected' | 'selectable' | 'disabled' = (isAvailable && isSelected) ? "selected" : (isAvailable && !isSelected) ? "selectable" : "disabled"
                            const filterLabel = t("ecommerce.pages.facets-product-available")
                            return (<CheckBoxFilter filterState={filterState} onClick={() => manageSelectedProductsAvailabilities(productAvailability)} filterLabel={filterLabel}/>)
                        })
                    }
                </div>
                {products && products.results &&
                <StyledGrid>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"row products-list"}>
                                {products.results.map((product: any, i: number) => {
                                    let _esInfo
                                    if(esInfo && esInfo.length > 0){
                                        const es = esInfo.find((obj: any) => obj.hasOwnProperty(product.key));
                                        if(es){
                                            _esInfo = es[product.key]
                                        }
                                    }
                                    return (<ListItem item={product} i={i} esInfo={_esInfo}/>)
                                })}
                            </div>
                        </div>
                    </div>
                </StyledGrid>
                }
            </div>
        </div>
        , container
    )
};

export const ColorFilter = (props: any) => {
    const {
        filterState,
        onClick,
        filterLabel,
        hoverText,
        colorName
    } = props;

    return (
        <div className="d-flex align-items-center mb-1">
            <StyledColorButton
                className={filterState}
                onClick={onClick}
                disabled={filterState === "disabled"}
                bgColor={filterLabel}
                title={hoverText}
                bgImg={colorName}
            >
            </StyledColorButton>
        </div>
    )
};

export const ButtonFilter = (props: any) => {
    let {
        filterState,
        filterLabel,
        onClick,
        style
    } = props;

    return (
        <div className="d-inline-block mr-1 align-items-center mb-1">
            <StyledButton
                style={style}
                className={filterState}
                onClick={onClick}
                disabled={filterState === "disabled"}
            >
                {filterLabel}
                {props.children}
            </StyledButton>
        </div>
    )
};

export const CheckBoxFilter = (props: any) => {
    const {
        filterState,
        filterLabel,
        onClick,
    } = props;

    const isChecked = filterState === 'selected';
    const isDisabled = filterState === 'disabled';

    return (
        <div className="d-flex align-items-center mb-1">
            <StyledCheckboxButton>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={onClick} // l'evento di onChange sarà gestito dalla funzione onClick passata
                    disabled={isDisabled}
                    className="toggle__input"
                />
               
                <span className="toggle-track">
				<span className="toggle-indicator">
					<span className="checkMark">
						<svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
							<path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"/>
						</svg>
					</span>
				</span>
			</span>
                <span>{filterLabel}</span>
            </StyledCheckboxButton>
        </div>
    )
};

export const ListItem = (props: any) => {
    const { item, i, esInfo } = props
    const { t } = React.useContext(LabelContext)
    const { getSymbolBySku } = React.useContext(SymbolContext)
    const { addLineItem, addRemoveWishlistLineItem } = React.useContext(CartContext)
    const { wishlist, setOpenBackInStock, openBackInStock, userData } = React.useContext(GeneralContext)
    const [productInfo, setProductInfo] = React.useState<ProductInfo | null>(null)
    const [showBackInStock, setShowBackInStock] = React.useState(false)

    let backInStockActive = Boolean(BACK_IN_STOCK_LISTING_ENABLED === 'true')

    type ProductInfo = {
        sku: string
        displaySku?: string
        name?: string
        url?: string
        images?: any
        isBundle?: boolean
        isAvailable?: boolean
        description?: string
        isInWishlist?: boolean
        hasDiscount?: boolean
        formattedPrice?: string
        formattedDiscountedPrice?: string
        discountPercentValue: number
        labelAddToCartCta?: string
        itemWillArriveLater?: boolean
        itemInStockWithLimitedQta?: boolean
        itemInStock?: boolean
        availableQta?: number
        energyLabel?: any
        productSheetUrl?: string
        symbols?: any[]
        showBackInStock? : boolean
        showPrice?: boolean
    };

    React.useEffect(() => {
        if(openBackInStock){
            window.openBIS()
        }
    },[openBackInStock])

    React.useEffect(() => {
        if(item && item.masterVariant && item.productType){
            let _productInfo: ProductInfo = {sku: "", discountPercentValue: 0, itemInStock: false, itemWillArriveLater: false, itemInStockWithLimitedQta: false, availableQta: 0, energyLabel: undefined, productSheetUrl: undefined, symbols: [], showBackInStock: false, showPrice: true}
            _productInfo.sku = item.masterVariant.sku
            _productInfo.images = item.masterVariant.images
            _productInfo.isBundle = item.productType.obj && item.productType.obj.key === 'BUNDLE'

            let _currentPriceAndDiscountedPrice = getCurrentPrice(item.masterVariant.prices, userData)
            _productInfo.showPrice = _currentPriceAndDiscountedPrice?.value?.centAmount > 0
            let _isInWishlist: any = false
            if (wishlist && wishlist.lineItems.length > 0) {
                _isInWishlist = wishlist.lineItems.some((item: any) => (item.variant && item.variant.sku === _productInfo.sku));
            }

            _productInfo.isInWishlist = _isInWishlist

            _productInfo.labelAddToCartCta = t("ecommerce.common.product.addToCart")

            const attrs = PRODUCT_WITH_LIMITED_QTA_ENABLED.split("|");

            if(_productInfo.isBundle){
                const filteredBundles = removeBundleCancelledFromProductProjection([item])
                if(filteredBundles.length <= 0){
                    setProductInfo(null)
                    return
                }

                let _bundleInfo = getBundlePrice(item, userData, false)
                _productInfo.isAvailable = _bundleInfo.isAvailable
                setShowBackInStock(!_productInfo.isAvailable && backInStockActive)

                let bundleDescription = item.obj ? item.obj?.masterData?.current?.description : item.description
                let descriptionAttr = item.masterVariant.attributes.find((attribute: any) => { return attribute.name === 'description-for-cart' })
                if(descriptionAttr){
                    bundleDescription = descriptionAttr['value']
                }
                _productInfo.description = bundleDescription[PROJECT_LANGUAGE]
                _productInfo.url = BRX_BUNDLE_URL + _productInfo.sku
                _productInfo.displaySku = undefined
                _productInfo.hasDiscount = _bundleInfo.discountPercentValue > 0
                _productInfo.formattedPrice = _bundleInfo.productsPriceFormatted
                _productInfo.formattedDiscountedPrice = parsePrice(_currentPriceAndDiscountedPrice.value)
                _productInfo.discountPercentValue = _bundleInfo.discountPercentValue

                if (PROJECT_SETTINGS.inventoryMode === "TrackOnly" || _bundleInfo?.isAvailable){
                    if(_bundleInfo.minQta <= 1){
                        _productInfo.itemWillArriveLater = true
                    } else {
                        if (PRODUCT_WITH_LIMITED_QTA_ENABLED != null) {
                            if (attrs.length === 2) {
                                const [enabled, qtaThresholdString] = attrs;
                                const qtaThreshold = Number(qtaThresholdString);
                                if (enabled === "true" && !isNaN(qtaThreshold)) {
                                    if(_bundleInfo.minQta && _bundleInfo.minQta >= 1 && _bundleInfo.minQta <= qtaThreshold){
                                        _productInfo.itemInStockWithLimitedQta = true
                                    }else if((["PL"].includes(PROJECT_COUNTRY) && _bundleInfo.minQta && _bundleInfo.minQta > qtaThreshold)) {
                                        _productInfo.itemInStock = true
                                    }
                                }
                            }
                        }
                    }
                }

            }else{
                _productInfo.symbols = getSymbolBySku( _productInfo.sku)
                _productInfo.energyLabel = getEnergyClassLabel(item.masterVariant, t)
                _productInfo.productSheetUrl = item.masterVariant.attributes.find((attr: any) => attr.name === 'product-sheet-url' && attr.value)?.value


                let itemInPreOrder = item.masterVariant.attributes.find((attr: any) =>{
                    return attr.name === 'pre-order' && attr.value === true
                });

                if (itemInPreOrder){
                    _productInfo.labelAddToCartCta = t("ecommerce.common.product.pre-order-cta")
                }

                _productInfo.isAvailable = item.masterVariant?.availability?.availableQuantity > 0 || (PROJECT_SETTINGS.inventoryMode === "TrackOnly" && item.masterVariant?.availability)
                setShowBackInStock(!_productInfo.isAvailable && backInStockActive)
                _productInfo.description = item.name[PROJECT_LANGUAGE]
                _productInfo.url = BRX_PRODUCTS_URL + _productInfo.sku
                _productInfo.displaySku = _productInfo.sku
                _productInfo.formattedPrice = parsePrice(_currentPriceAndDiscountedPrice.value)
                _productInfo.formattedDiscountedPrice = _currentPriceAndDiscountedPrice.discounted ? parsePrice(_currentPriceAndDiscountedPrice.discounted.value) : undefined
                _productInfo.hasDiscount = !!_productInfo.formattedDiscountedPrice
                _productInfo.discountPercentValue = 0;

                let availableQta = item.masterVariant?.availability?.availableQuantity
                _productInfo.availableQta = availableQta
                if((PROJECT_SETTINGS.inventoryMode === "TrackOnly" && item.masterVariant?.availability) || (availableQta > 0)){
                    if(availableQta <= 0){
                        _productInfo.itemWillArriveLater = true
                    }else if(PRODUCT_WITH_LIMITED_QTA_ENABLED != null){
                        if (attrs.length === 2) {
                            const [enabled, qtaThresholdString] = attrs;
                            const qtaThreshold = Number(qtaThresholdString);
                            if (enabled === "true" && !isNaN(qtaThreshold)) {
                                if(availableQta >= 1 && availableQta <= qtaThreshold){
                                    _productInfo.itemInStockWithLimitedQta = true
                                }else if((["PL"].includes(PROJECT_COUNTRY) && availableQta > qtaThreshold) || nordics.includes(PROJECT_KEY)) {
                                    _productInfo.itemInStock = true
                                }
                            }
                        }
                    } else if((["PL"].includes(PROJECT_COUNTRY) && availableQta > 2) || nordics.includes(PROJECT_KEY)){
                        _productInfo.itemInStock = true
                    }
                }

                if (_productInfo.hasDiscount && _currentPriceAndDiscountedPrice.discounted) {
                    const originalAmount = _currentPriceAndDiscountedPrice.value.centAmount;
                    const discountedAmount = _currentPriceAndDiscountedPrice.discounted.value.centAmount;
                    if (originalAmount > 0) {
                        _productInfo.discountPercentValue = Math.round(100 * (originalAmount - discountedAmount) / originalAmount);
                    }
                }

            }
            setProductInfo(_productInfo)
        }
    },[props, addRemoveWishlistLineItem])

    return ( productInfo &&
        <div key={i} className="listItem col-12 col-sm-6 col-lg-4 col-xl-3 product-preview">
            
            <div className="product-preview__top product-labels">
                {esInfo && esInfo.labels && esInfo.labels.map((label: any) => {
                    const val = label.labelKey !== "" ? t(`${label.labelKey}`) : label.text;
                    return (
                        <div className="label mr-1">
                            {val}
                        </div>
                    );
                })}
                {productInfo.symbols && productInfo.symbols.length > 0 &&
                    <>
                        {productInfo.symbols.map((symbol: any) => {
                            let visibilityClass = getContentVisibility(symbol.visibility, "inline")
                            return (
                                symbol.imageUrl && symbol.type === "label" && (
                                    <a className={`label-clickable mr-1 ${visibilityClass}`} onClick={() => window.symbolModal.showModal(symbol)}>
                                        <div className="label mb-1">
                                            {symbol.name}
                                        </div>
                                    </a>
                                )
                            );
                        })}
                    </>
                }
            </div>
            <div className="product-content">
                <a href={productInfo.url}>
                    <div className="product-preview__gallery" style={{"position":"relative"}}>
                        {productInfo.discountPercentValue > 0 &&
                            <span className="float-right" style={{"backgroundColor":"#640000", "color":"#FFFFFF", "padding":"0px 20px", "textTransform":"uppercase", "fontWeight":"bold", "position":"absolute", "top":"0px", "right": "0px"}} >-{productInfo.discountPercentValue}%</span>
                        }
                        {productInfo.images && productInfo.images.length > 0 &&
                            <img style={{width:"100%"}} src={productInfo.images[0].url} alt="" />
                        }
                    </div>

                    <div className="product-preview__header">
                        {productInfo.displaySku &&
                            <div className="name">{productInfo.displaySku}</div>
                        }

                        {productInfo.showPrice &&
                            <div className="promotion-preview">
                                <div className="promotion-preview__header">
                                    <div className="prices">
                                        {productInfo.hasDiscount &&
                                        <>
                                            <div className="price price--strikethrough">{productInfo.formattedPrice}</div>
                                            <div className="price price--discounted">{productInfo.formattedDiscountedPrice}</div>
                                        </>
                                        }
                                        {!productInfo.hasDiscount &&
                                        <div className="price ">{productInfo.formattedPrice}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    {esInfo && esInfo.description && esInfo.description.value ?
                        <div className="product-preview__description">{esInfo.description.value.replaceAll(","," | ")}</div>
                        :
                        <div className="product-preview__description">{productInfo.description}</div>
                    }
                </a>
                
                <div className={`mx-0 energy_label`}>
                    {SHOW_ENERGY_LABEL && (productInfo.energyLabel || (productInfo.productSheetUrl && productInfo.productSheetUrl !== "")) &&
                    <>
                        {productInfo.energyLabel &&
                            <a target="_blank" rel="noreferrer" href={`https://pi-exchange.smeg.it/energy/${productInfo.displaySku}/${PROJECT_LANGUAGE}-${PROJECT_COUNTRY}`}>
                                <img src={productInfo.energyLabel.src} alt={productInfo.energyLabel.alt} />
                            </a>
                        }

                        {productInfo.productSheetUrl && productInfo.productSheetUrl !== "" &&
                            <a href={productInfo.productSheetUrl?.replaceAll(" en-GB", " en-IE")} target="_blank" rel="noreferrer" className={`font-weight-light ${productInfo.energyLabel ? 'pl-3' : ''}`}>{t("product.productSheetLabel")}</a>
                        }

                    </>
                    }
                </div>

                <div className="energy_label">
                    {productInfo.symbols && productInfo.symbols.length > 0 &&
                        <>
                            {productInfo.symbols.map((symbol: any) => {
                                let visibilityClass = getContentVisibility(symbol.visibility, "inline")
                                return (
                                    symbol.imageUrl && symbol.type !== "label" && (
                                        <div className={visibilityClass} key={symbol.id}>
                                            <div className="float-right">
                                                <img
                                                    className="icon-30"
                                                    onClick={() => window.symbolModal.showModal(symbol)}
                                                    src={symbol.imageUrl}
                                                    alt={symbol.imageAlt}
                                                    title={symbol.imageTitle}
                                                />
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </>
                    }
                </div>

            </div>

            <div className="product-footer"/>

            <div className="row product-links">
                <div className="product-link__buy btn">
                    {productInfo.sku && productInfo.isAvailable &&
                        <button data-id={productInfo.sku} onClick={() => { addLineItem(productInfo.sku); openCartLayer(); }} className="btn_addToCart button-primary" title={productInfo.labelAddToCartCta}>
                            {productInfo.labelAddToCartCta}
                        </button>
                    }
                    {!productInfo.isAvailable &&
                        <>
                            <div  style={{"lineHeight": "18px", "padding": "0.375rem 0"}} className="product-messages text-center focus" dangerouslySetInnerHTML={{ __html: t("ecommerce.common.product.isOutOfStock") }}/>
                            {showBackInStock && (
                                <button onClick={() => {
                                    setOpenBackInStock({
                                        imageUrl: productInfo.images[0].url,
                                        sku: productInfo.sku,
                                        name: productInfo.name,
                                        title: t('ecommerce.common.product.backInStockTitle'),
                                        description: t('ecommerce.common.product.backInStockDescription')
                                    })
                                }} className="btn_secondary btn_backInStock">
                                    <span className="icon"><i className="far fa-bell"/></span>
                                    <span className="testo">{t('ecommerce.common.product.notifyMeWhenBackInStock')}</span>
                                </button>)
                            }
                        </>
                    }
                    {productInfo.itemWillArriveLater &&
                        <div className="text-center focus-info focus-info-small mt-3" style={{color: 'var(--color-alert-light)'}}>{t('ecommerce.checkout.listing.item-will-arrive-late')}</div>
                    }
                    {productInfo.itemInStockWithLimitedQta &&
                        <div className="text-center focus-info focus-info-small mt-3" style={{ color: '#faa914' }}>{t('ecommerce.checkout.item-in-stock-limited-qta').replace("{qta}",productInfo.availableQta ? productInfo.availableQta.toString() : '')}</div>
                    }
                    {productInfo.itemInStock &&
                        <div className="text-center focus-info focus-info-small mt-3" style={{ color: 'var(--color-success)' }}>{t('ecommerce.checkout.item-in-stock')}</div>
                    }
                </div>
            </div>

            <div className="product-messages text-center"/>

            <div className="product-link__wishlist text-center">
                <div className="product-link__wishlist btn">
                    <button data-id={productInfo.sku} onClick={() => addRemoveWishlistLineItem(productInfo.sku)} className={`${productInfo.isInWishlist && "inWishlist"} btn_addToWishlist`} title="Wishlist">
                        <span className="icon"/>
                        <span className="testo">{t("ecommerce.common.product.addToWishlist")}</span>
                        <span className="testo2">{t("ecommerce.common.product.addedToWishlist")}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};