import React from "react";
import {COOKIE_PATH, COOKIE_PATH_ALTERNATIVE, COOKIES} from "../core/APIUtils";
import { CartContext } from "../core/cart.context";
import { GeneralContext } from "../core/general.context";
import { RESTContext } from "../core/rest.context";
import { UserContext } from "../core/user.context";

export const TokenDetection = (props: any) => {
  const { token } = React.useContext(RESTContext);
  const { getAnonymousToken } = React.useContext(RESTContext);
  const { userData  } = React.useContext(GeneralContext);
  const { getActiveCart } = React.useContext(CartContext);
  const { getUserData } = React.useContext(UserContext);

  React.useEffect(() => {
    if (token) {
      COOKIES.set('cookie_access_token', token, { path: COOKIE_PATH })
      if(COOKIE_PATH_ALTERNATIVE){
        COOKIES.set('cookie_access_token', token, { path: COOKIE_PATH_ALTERNATIVE })
      }
      if (!userData) {
        getUserData();
      } else {
        getActiveCart();
      }
    } else {
      getAnonymousToken();
    }
  }, [token]);

  return null;
};