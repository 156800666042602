import { Observable } from "rxjs/internal/Observable";
import { ICart, ICartPrices, IKlarna, ITotalPrice, IPayU, IYounited, IBuyBox } from "./cart.models";
import { IUserData } from "./user.models";

export interface ICredentials {
  email: string;
  password: string;
}

export interface IToken {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  refresh_token?: string;
}

// export interface ICalls {
//   Authorization: {
//     getToken: () => Observable<any>
//     getAnonymousToken: () => Observable<any>
//     getCustomerToken: (credentials: ICredentials) => Observable<any>
//   },
//   Project: {
//     getCustomerDetails: () => Observable<any>
//     getActiveCart: () => Observable<any>
//   }
// }

export interface ICheckoutContext {
  shippingType: EShippingType
  setShippingType: (value: EShippingType) => void
  tempAddresses: any
  setTempAddresses: (value: any) => void
  privacy: any
  setPrivacy: (value: any) => void
  consent: any
  setConsent: (value: any) => void
  externalWarrantyCheck: boolean
  setEternalWarrantyCheck: (value: boolean) => void
  nlConsentValue: boolean
  setNlConsentValue: (value: boolean) => void
  commercial: any
  setCommercial: (value: any) => void
  activeStep: number
  setActiveStep: (value: number) => void
  focusPrivacy: boolean
  setFocusPrivacy: (value: boolean) => void
  consent3Party: boolean
  setConsent3Party: (value: boolean) => void
  clickedPay: boolean
  setClickedPay: (value: boolean) => void
  clickedPayWithPaypal: boolean
  setClickedPayWithPaypal: (value: boolean) => void
  paypalError: string
  setPaypalError: (value: string) => void
  applepayEnabled: boolean
  setApplepayDisabled: (value: boolean) => void
  lineItemWithNoAvailability: boolean
  setLineItemWithNoAvailability: (value: boolean) => void
  sepaComponent: any
  setSepaComponent: (value: any) => void
  adyenComponent: any
  setAdyenComponent: (value: any) => void
  paypalComponent: any
  setPaypalComponent: (value: any) => void
  klarnaComponent: any
  setKlarnaComponent: (value: any) => void
  bancontactComponent: any
  setBancontactComponent: (value: any) => void
  applepayComponent: any
  setApplepayComponent: (value: any) => void
  idealComponent: any
  setIdealComponent: (value: any) => void
  showPaypal: boolean
  setShowPaypal: (value: boolean) => void
  loadingPaypal: boolean
  setLoadingPaypal: (value: boolean) => void
  enabledGiftCardEditButton: boolean
  setEnabledGiftCardEditButton: (value: boolean) => void
  giftcardInsideCard: boolean
  setGiftcardInsideCart: (value: boolean) => void
  hasGiftCards: boolean
  setHasGiftCards: (value: boolean) => void
  showGiftCardSelect: boolean
  setShowGiftCardSelect: (value: boolean) => void
  unmountAdyenComponents: () => void
  onAdditionalDetails: (state: any, component: any) => void
  loadIdealForm: () => void
  loadBancontactForm: () => void
  handleOnChange: () => void
  hasExternalWarrantyService: boolean
  loadAdyenForm: () => void
  loadApplePayForm: () => void
  loadSepaForm: () => void
  loadPaypalForm: () => void
  loadPaypalDirectForm: () => void
  payWithBankTransfer: () => void
  closeOrderWithGiftCard: () => void
  payLaterWithPayByLink: () => void
  loadKlarnaForm: () => void
  redirectToPayU: (isRatyPay: boolean) => void
  redirectToYounitedPay: (maturity: number, phone: string) => void
  redirectToBuyBox: () => void
  redirectToPayFast: () => void
  checkoutStep: number
  setCheckoutStep: (step: number) => void
  isMandatoryFieldsFlagged: () => boolean
  setAddAddressFormVisibility: (type: 'shipping' | 'billing', visible: boolean) => void
  addAddressFormVisibility: {
    shipping: boolean;
    billing: boolean;
  }
  isAddressEdit: {
    shipping: boolean;
    billing: boolean;
  }
  setIsAddressEdit: (type: 'shipping' | 'billing', value: boolean) => void
  selectedAddress: {
    shipping: any | null;
    billing: any | null;
  }
  setSelectedAddress: (type: 'shipping' | 'billing', address: any | null) => void
  showListAddress: {
    shipping: boolean;
    billing: boolean;
  }
  setShowListAddress: (type: 'shipping' | 'billing', show: boolean) => void
  numberAddressAvailable: {
    shipping: number
    billing: number
  }
  setNumberAddressAvailable: (type: 'shipping' | 'billing', value: number) => void
  useShippingAddressAsBilling: boolean
  setUseShippingAddressAsBilling : (useIt: boolean) => void
  makeSignUp: boolean
  setMakeSignUp: (makeIt: boolean) => void
}

export interface IGeneralContext {
  userData: IUserData
  setUserData: any
  orders: any
  setOrders: any
  cart: ICart
  setCart: any
  currentOrder: any
  setCurrentOrder: any
  forgotPasswordSent: any
  setForgotPasswordSent: any
  resetPasswordState: any
  setResetPasswordState: any
  orderCreated: any
  setOrderCreated: any
  wishlist: ICart
  setWishlist: any
  shippingMethods: any
  setShippingMethods: any
  temporaryOrder: any
  setTemporaryOrder: any
  klarna: IKlarna
  setKlarna: any
  openBackInStock: any
  setOpenBackInStock: any
  payU: IPayU
  setPayU: any
  giftCards: IGiftCard[]
  setGiftCards: any
  giftcardActivated: any
  setGiftcardActivated: any
  giftCardsMovements : any
  setGiftcardMovements: any
  younited: IYounited
  setYounited: any
  younitedUri: any
  setYounitedUri: any
  buyBox: IBuyBox;
  setBuyBox: any;
  setInvoiceValue: (val: boolean) => void
  invoiceValue: boolean
  payFastSignature: string
  setPayFastSignature: (signature: string) => any
  symbols: any
  setSymbols: (symbols: any) => void
}

export interface ICartContext {
  addLineItem: (id: string, skipShowPopup?: boolean, skipShowCartLayer?: boolean, qta?: number) => void;
  addServiceLineItem: (sku: string, referenceProductId: string) => void;
  removeLineItem: (id: string, deleteRow: boolean, quantinty?: number) => void;
  removeServiceLineItem: (id: string) => void;
  addPromo: (id: string) => void;
  removePromo: (id: string) => void;
  getActiveCart: () => void;
  setAddress: (address: any, type: EAddressType, cart_?: ICart) => Observable<any>;
  shippingMethods: any;
  loadShippingMethods: () => void;
  setShippingMethod: (id: string) => void;
  resetCartForCheckout: (tempAddresses?: any, forceResetShippingAddress?: boolean) => void;
  hasServicesInCart: boolean;
  numberOfProducts: number;
  productsPrices: ICartPrices;
  servicesPrices: ICartPrices;
  savingTotalPrice: ITotalPrice;
  isEmptyCart: boolean;
  addRemoveWishlistLineItem: (sku: string) => void;
  wishlist: ICart;
  isEmptyWish: boolean;
  numberOfProductsWish: number;
  setSendInvoice: (send: boolean) => void;
  setPickupInStore: (shippingMethodId: any) => void;
  createPayment: any;
  createOrderWithoutPayment: any;

  setSubscriptionOnCart: (subscribe: boolean) => void;
  setNoteOnCart: (note: string) => void;
  getOrders: () => void;
  additionalsDetailsPayment: any;

  createCartIfCurrentHavePaymentSuccess: () => void;
  checkIsCartChange: (p_cart: any) => void;
  isCartChanged: boolean;
  recalculate: (p_cart: any) => void;
  moveToWishlist: (lineItemId: string) => void;
  klarna: IKlarna;
  getKlarna: (cardId: string) => void;
  getKlarnaCreate: (orderId: string) => void;

  setSkuForPopup : (sku: string) => void;
  skuForPopup : string;
  setConsentThrdParty: (consent: boolean) => void;
  checkExternalPromoAppliedAndValidateCart: (cart: ICart) => void
  updateExternalPromoToCart: (promotion: any) => void
  externalPopup: boolean
  setExternalPopup : (open: boolean) => void
  giftPromoId: string
  setGiftPromoId: (id: string) => void
  promoError: any;
  showError: boolean;
  setShowError: (val: boolean) => void;
  processingOrder: boolean;
  setProcessingOrder : (open: boolean) => void
  setNlConsent: (val: boolean) => void
  payU: IPayU;
  startPayU: (cartId: string, isRatyPay: boolean) => void;
  getPayUCreate: (cartId: string) => void;

  //gift card
  loadGiftCards: (onlyActive: boolean) => void;
  setApplyGiftCardToCart: any;
  setRemoveGiftCardFromCart: any;
  setRemoveAllGiftCardFromCart: any;
  differenceBy: any;
  giftCards: IGiftCard[];
  setGiftCards: () => void;
  selectedGiftcards: any;
  setSelectedGiftcards : (giftcards: any) => void
  giftCardsMovements : any
  setGiftcardMovements : (data: any) => void
  loadingGiftCardsMovements: boolean
  populateGiftCardRecipientInfo: (formRef: React.RefObject<HTMLFormElement>) => void
  checkGiftCardMaxQtaReached: (cart: ICart) => void
  maxGiftCardsReached: boolean

  younited: IYounited;
  getYounitedOffers: (cartId: string, totalAmount: number) => void;
  initYounitedContract: (cartId: string, maturity: number, phone: string) => void;
  confirmYounitedContract: (cartId: string) => void;
  getSelectedShippingMethod: (cart: ICart, shippingMethods: any) => any;
  createPayPalOrder: (cartId: string) => Promise<any>;
  capturePayPalOrder: (cartId: string, paypalOrderId: string) => Promise<any>;
  buyBox: IBuyBox;
  initBuyBoxPayment:(cartId: string) => void;
  confirmBuyBoxPayment:(cartId: string, buyBoxToken: string, payerId: string) => void;
  generatePayFastSignature: (cartId: string, projectKey: string) => void
  selectedShippingMethod: any
  setSelectedShippingMethod: (shippingMethod: any) => void
}

export interface IUserContext {
  signup: (data: any) => void
  logout: () => void
  login: (username: string, password: string, pToken?: any, callback?: any) => void
  activateGiftcard: (serial: string, cvc: string) => void
  giftcardActivated: any
  setGiftcardActivated: any
  forgotPassword: (email: string) => void
  resetPassword: (token: any, newPassword: string) => void
  verifyCostumerEmail: (token: string) => void
  getUserData: () => void
  addAddress: (address: any, type: EAddressType, userData_?: IUserData, cart_?: ICart) => Observable<any>
  removeAddress: (address: any) => void
  updateCustomer: (customer: any) => void
  changePwdCustomer: (customer: any) => void
  setCustomerEmail: (email: string) => void
  forgotPasswordSent: boolean
  resetPasswordState: number
  messageActivationMail: string
  signupFromCheckout: (email: string, password: string, newletterSubscribe: boolean, consentProfiling: boolean, shippingAddress: any, billingAddress: any, useShippingAddressAsBilling: boolean, shippingType: EShippingType) => Observable<any>
  signupFromKlarnaCheckout: (email: string, password: string, newletterSubscribe: boolean) => Observable<any>
  loginAsGuestFromCheckout: (email: string, shippingAddress: any, billingAddress: any, useShippingAddressAsBilling: boolean, shippingType: EShippingType, skipAddress: boolean) => Observable<any>
  addMultipleAddress: (addressTypePairs: { address: any; type: EAddressType }[], updateCart: boolean, shippingType: EShippingType) => Observable<any>
}

export interface IRESTContext {
  token: IToken | null;
  setToken: (token: IToken) => void;
  ajax$: (call: any, token?: any, body?: any, async? : boolean, contentType?: string) => Observable<any>;
  ajaxMiddleware$: (call: any, token?: any, body?: any) => Observable<any>;
  getAnonymousToken: () => void;
  AWSlogger: (log: any) => any;
}


export interface ILabelContext {
  t: (key: string) => string;
  labels: any
}

export interface ISymbolContext {
  getSymbolBySku: (key: string) => any,
  symbols: any
}

export interface IDetectClickOutSide {
  condition: any;
  action: any;
  target: string;
}

export interface IAlertOptions {
  type?: any;
}

export interface IBannerOptions {
  type?: any;
  description?: any;
  onConfirm?: () => void;
}

export interface IAlert {
  show: (options: IAlertOptions) => void;
  hide: () => void;
  state: IAlertState;
}

export interface ILoader {
  show: () => void;
  hide: () => void;
  state: IAlertState;
}


export interface IBanner {
  confirm: (options: IBannerOptions) => void;
  show: (options: IBannerOptions) => void;
  hide: () => void;
  state: IBannertState;
}

export interface IAlertState {
  show: boolean;
  options?: IAlertOptions;
}

export interface ILoaderState {
  show: boolean;
  options?: IAlertOptions;
}

export interface IBannertState {
  show: boolean;
  bannerType?: string;
  options?: IBannerOptions;
}

export enum EAjaxMethods {
  GET = "GET",
  POST = "POST"
}

export enum EShippingType {
  Home = "home",
  Pickup = "pickup"
}

export enum EAddressType {
  Shipping = "shipping",
  Billing = "billing"
}

export enum CTinventoryMode {
  ReserveOnOrder = "reserveOnOrder",
  TrackOnly = "trackOnly",
  None = "none",
}

export enum currency {
  '€' = "EUR",
  '$' = "USD",
}

export interface ILastModifiedBy {
  clientId: string;
  isPlatformClient: boolean;
  anonymousId: string;
}

export interface ICreatedBy {
  clientId: string;
  isPlatformClient: boolean;
  anonymousId: string;
}

export interface IGiftCard {
  email: any,
  giftCardName: any,
  value: any,
  residualValue: any,
  sku: any,
  img: any,
  id_order: any,
  id: string,
  name: any,
  surname: any
}

export interface IGiftCardMovement {
  value: any,
  id: string,
  isDeleted: boolean
  createdDate: any
  orderNumber: string
}

export interface IAddAddressFormMethods {
  addAddressFormValidate: () => Promise<boolean>
  addAddressFormGetData: () => Promise<any>
  addAddressFormReset: () => void
}